@use '../utilities/scale';
@use '../utilities/styles';

.icon {
    @include styles.icon-base(scale.px-to-rem(32), scale.px-to-rem(32));

    @include media-breakpoint-up(md) {
        width: scale.px-to-rem(40);
        height: scale.px-to-rem(40);
    }
}

.icon-prev-circled {
    @include styles.icon-hover('prev_circled');
}

.icon-next-circled {
    @include styles.icon-hover('next_circled');
}

.icon-circle {
    @include styles.icon-hover('circle');
}

.icon-scilog {
    @include styles.icon-hover('scilog_light');
}

.icon-arrow-up {
    @include styles.icon-text-color('arrow');
}

.icon-arrow-right {
    @include styles.icon-text-color('arrow', 90deg);
}

.icon-arrow-down {
    @include styles.icon-text-color('arrow', 180deg);
}

.icon-arrow-left {
    @include styles.icon-text-color('arrow', -90deg);
}

.icon-arrow-right-circled {
    @include styles.icon-text-color('arrow_circled', 90deg);
}

.icon-arrow-left-circled {
    @include styles.icon-text-color('arrow_circled', -90deg);
}

.icon-spotlight {
    @include styles.icon-text-color('spotlight');
}

.icon-location {
    @include styles.icon-text-color('location');
}

.icon-telephone {
    @include styles.icon-text-color('telephone');
}

.icon-mail {
    @include styles.icon-text-color('mail');
}

.icon-clock {
    @include styles.icon-text-color('clock');
}

.icon-play {
    @include styles.icon-text-color('play');
}

.icon-career-stages {
    @include styles.icon-text-color('career_stages');
}

.icon-keywords {
    @include styles.icon-text-color('keywords');
}

.icon-start {
    @include styles.icon-text-color('start');
}

.icon-end {
    @include styles.icon-text-color('end');
}

svg.icon-search {
    height: auto; // overwrite height of HTML attribute
    fill: none;
    stroke: var(--primary-color-text);
    aspect-ratio: 1;

    @include media-breakpoint-up(md) {
        stroke-width: 1.5;
    }
}
