@use '../utilities/styles';

.box-grid {
    position: relative;
}

.box-grid-top-closed {
    margin: calc(var(--bs-gutter-y) * 0.5) 0;
    border: styles.border-thin(1, transparent);

    &:first-child {
        .box-grid-horizontal::before {
            position: absolute;
            top: calc(var(--bs-gutter-y) * -0.5);
            left: 0;
            width: 100%;
            height: 0;
            border-top: styles.border-thin();
            content: '';
        }
    }

    @include media-breakpoint-up(md) {
        &:not(.search-list-item):nth-child(2) {
            .box-grid-horizontal::before {
                position: absolute;
                top: calc(var(--bs-gutter-y) * -0.5);
                left: 0;
                width: 100%;
                height: 0;
                border-top: styles.border-thin();
                content: '';
            }
        }
    }

    .box-grid-horizontal::after {
        bottom: calc(var(--bs-gutter-y) * -0.5);
    }
}

.box-grid-horizontal::after { // stylelint-disable-line no-descending-specificity
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: styles.border-thin();
    content: '';
}

@include media-breakpoint-up(md) {
    .box-grid {
        &:nth-child(even), &:last-child, &.search-list-item {
            .box-grid-vertical::after {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 100%;
                border-right: styles.border-thin();
                content: '';
            }
        }
    }

    .box-grid-vertical {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            border-left: styles.border-thin();
            content: '';
        }
    }
}

.box-list {
    position: relative;
}

.box-list-top-closed {
    margin: calc(var(--bs-gutter-y) * 0.5) 0;
    border: styles.border-thin(1, transparent);

    &:first-child {
        .box-list-horizontal::before {
            position: absolute;
            top: calc(var(--bs-gutter-y) * -0.5);
            left: 0;
            width: 100%;
            height: 0;
            border-top: styles.border-thin();
            content: '';
        }
    }

    .box-list-horizontal::after {
        bottom: calc(var(--bs-gutter-y) * -0.54);
    }
}

@include media-breakpoint-up(md) {
    .box-list-vertical {
        flex: 1;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            border-left: styles.border-thin();
            content: '';
        }
    }

    .box-list-vertical::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 100%;
        border-right: styles.border-thin();
        content: '';
    }
}

.box-list-horizontal::after { // stylelint-disable-line no-descending-specificity
    position: absolute;
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: styles.border-thin();
    content: '';
}

.box-flex {
    display: flex;
    justify-content: space-between;
}
