@use '../utilities/scale';
@use '../utilities/styles';
@use '../utilities/typography';

.header-bar {
    border-bottom: styles.border-thin();
}

.header-bar-col {
    position: relative;
    display: flex;
    padding-top: scale.px-to-rem(10);
    padding-bottom: scale.px-to-rem(6);
    font-size: var(--font-size-small);
}

.header-bar-icon {
    width: scale.px-to-rem(32);

    img {
        width: auto;
        height: scale.px-to-rem(18);
    }
}

.header-bar-content {
    display: flex;
    flex-wrap: wrap;
    gap: scale.px-to-rem(15);
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header-bar-title, .header-bar-author {
    @include typography.uppercase();
}

.header-bar-tag {
    align-self: flex-start;
    margin-top: scale.px-to-rem(-1);
    margin-left: auto;
    border-radius: scale.px-to-rem(11);
    padding: scale.px-to-rem(1) scale.px-to-rem(9) 0;
    background-color: var(--secondary-color);
}

.page-header-text {
    .header-bar {
        order: -1; // the bar is only visually above the H1 which comes first in DOM because of its importance
    }
}

.header-bar-title {
    margin-right: auto;
}

@include media-breakpoint-up(sm) {
    .header-bar-author {
        margin-left: auto;
    }
}

@include media-breakpoint-up(md) {
    .header-bar-author {
        margin-right: auto;
    }

    .header-bar-title, .header-bar-author {
        margin-top: scale.px-to-rem(10);
    }

    .header-bar-tag {
        border-radius: scale.px-to-rem(18);
        padding: scale.px-to-rem(8) scale.px-to-rem(14) scale.px-to-rem(7);
    }

    .header-bar-col {
        padding-top: scale.px-to-rem(28);
        padding-bottom: scale.px-to-rem(22);
    }
}
