@import 'skip-links';
@import 'menu';
@import 'bar';
@import 'breadcrumbs';

.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;

    @include media-breakpoint-up(xxxl) {
        left: 50%;
        max-width: $max-page-width;
        translate: -50% 0;
    }
}
