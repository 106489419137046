.dialog-container[aria-hidden='true'] {
    display: none;
}

.dialog-close {
    display: none;
}

body.dialog-open {
    overflow: hidden;

    .dialog-close {
        display: block;
    }
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);
    animation: fade-in 200ms both;

    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
