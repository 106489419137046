/*
 * TYPO3 content elements and plugins
 */

@use '../utilities/scale';
@use '../utilities/styles';
@use '../utilities/typography';
@use '../utilities/layout';

// teaser content elements (eg "news") or plugins (eg "fwfsolr_page_teaser")
.segment-teaser-ce {
    position: relative;
    overflow-x: hidden;

    .swiper-scrollbar-line, .teaser-list-line {
        position: absolute;
        left: 0;
        width: 100%;
        border-bottom: styles.border-thin();
    }

    .swiper-scrollbar-line {
        top: calc(var(--swiper-scrollbar-size) * 0.4);
        height: 0;
    }

    .swiper-scrollbar.swiper-scrollbar-horizontal {
        top: 0;

        &::before {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        &.swiper-mobile-only {
            .swiper-scrollbar-line {
                display: none;
            }
        }
    }

    .frame-subtype-page-teaser-special {
        margin-top: scale.px-to-rem(30);
    }
}

.teaser-ce-navigation {
    font-size: var(--font-size-small);

    a, .button-spacer {
        margin-bottom: 0;
    }
}

.teaser-ce-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: scale.fluid-size(30, 80, 576, 1642);
    margin-bottom: scale.px-to-rem(25);

    h2 {
        margin: 0;
    }

    @include media-breakpoint-up(sm) {
        margin-bottom: scale.fluid-size(25, 55, 576, 1642);

        h2 {
            font-size: scale.fluid-size(30, 60, 576, 1642);
        }
    }

    @include media-breakpoint-up(xxl) {
        h2 {
            padding-right: 1em;
        }

        .teaser-ce-navigation {
            display: flex;
            flex-shrink: 0;
        }
    }
}

.teaser-ce-footer {
    @include media-breakpoint-up(xxl) {
        display: none;
    }

    .teaser-ce-navigation {
        display: flex;

        @include media-breakpoint-down(xxl) {
            margin-bottom: scale.px-to-rem(23);
        }
    }
}

.teaser-ce-content {
    &.teaser-ce-small {
        .card-tag, .card-eyebrow {
            display: none;
        }

        .swiper-slide {
            .card-title, .card-with-text .card-text p {
                margin-top: scale.px-to-rem(8);
            }

            .card-with-text .card-text p {
                line-height: 1;
            }

            .card-title {
                line-height: 1.1;
            }
        }
    }

    &.teaser-ce-large {
        .card-title, .card-with-text .card-text {
            font-weight: 430;
        }
    }

    &.teaser-ce-wide {
        position: relative;
        margin-left: -18px; // ~ 2/3 of horizontal padding (mobile)

        .teaser-ce-footer {
            margin-left: 18px;
        }

        @include media-breakpoint-up(md) {
            .teaser-ce-navigation-desktop {
                position: absolute;
                right: 58%;
                bottom: 0;
                z-index: 2; // raise above swiper to make buttons work
                display: block;
            }

            .teaser-ce-footer {
                display: none;
            }
        }
    }

    &.teaser-ce-list, &.teaser-ce-focus-big, &.teaser-ce-focus-small {
        padding-top: scale.px-to-rem(25);

        .row {
            --bs-gutter-y: var(--bs-gutter-x);
        }

        .box-grid-horizontal::after {
            bottom: calc(var(--bs-gutter-y) * -0.5);
        }
    }

    .swiper {
        &.swiper-simple {
            @include media-breakpoint-up(md) {
                margin-bottom: scale.px-to-rem(7);
            }

            @include media-breakpoint-up(xxl) {
                margin-bottom: scale.px-to-rem(30);
            }
        }
    }
}

.teaser-ce-startpage {
    display: flex;
    flex-direction: column;
    margin-bottom: scale.px-to-rem(30);

    .teaser-category {
        display: flex;
        align-items: center;
        order: 1;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    h2 {
        order: 2;
        margin: scale.px-to-rem(12) 0 scale.px-to-rem(20) 0;

        @include media-breakpoint-up(sm) {
            margin: scale.px-to-rem(18) 0 scale.px-to-rem(32) 0;
        }
    }

    .teaser {
        order: 3;
    }

    .card-image, .teaser-video {
        order: 4;
    }

    @include media-breakpoint-up(sm) {
        margin: scale.px-to-rem(10) 0 scale.px-to-rem(58) 0;

        .teaser {
            margin: 0 0 scale.px-to-rem(58) 0;
        }
    }

    @include media-breakpoint-up(xl) {
        margin: scale.px-to-rem(20) 0 scale.px-to-rem(88) 0;

        .teaser {
            margin: 0 0 scale.px-to-rem(88) 0;
        }
    }
}

.teaser-list-item {
    &:last-child {
        .box-grid-horizontal::after {
            border-bottom-style: none;

            @include media-breakpoint-up(md) {
                border-bottom-style: solid;
            }
        }
    }

    > * {
        height: 100%; // for .card-with-text
    }
}

@include media-breakpoint-up(md) {
    .teaser-ce-content {
        width: calc(50% + var(--bs-gutter-x) * 0.5);

        &.teaser-ce-large {
            &:not(:has(.swiper-initialized)) {
                max-width: scale.px-to-rem(668);
                margin-right: scale.px-to-rem(25);
            }

            &:has(.swiper) {
                width: auto;
            }
        }

        &.teaser-ce-small {
            &:has(.swiper) {
                width: auto;
            }

            .swiper-slide {
                .card-title, .card-with-text .card-text { // stylelint-disable-line no-descending-specificity
                    font-size: scale.fluid-size(20, 30, 768, 1642);
                }
            }

            &:not(:has(.swiper-initialized)) {
                .swiper-slide {
                    max-width: scale.px-to-rem(323);
                    margin-right: scale.px-to-rem(25);
                }
            }
        }

        &.teaser-ce-wide {
            width: auto;
            margin-left: calc(var(--bs-gutter-x) * -1);
        }

        &.teaser-ce-focus-big, &.teaser-ce-focus-small, &.teaser-ce-list {
            display: flex;
            flex-flow: wrap;
            width: 100%;
            padding-bottom: var(--bs-gutter-x);
        }
    }
}

@include media-breakpoint-up(xxl) {
    .teaser-ce-content {
        &.teaser-ce-small {
            width: auto;
        }
    }
}

// Teaser Focus elements

.teaser-ce-focus-big, .teaser-ce-focus-small {
    // Make sure the padding doesn't grow in resize text mode
    .row > * {
        padding-right: 25px;
        padding-left: 25px;
    }

    .card {
        height: 100%;
    }

    .card-with-text .card-text p {
        margin-bottom: 0;
    }

    .card-title {
        height: auto;
    }

    .card-title, .card-subtitle {
        -webkit-line-clamp: initial;
    }

    + .teaser-ce-footer {
        @include media-breakpoint-down(md) {
            margin-top: scale.px-to-rem(32);
        }
    }
}

.teaser-ce-focus-big {
    @include media-breakpoint-up(xl) {
        .box-grid:nth-child(even) .box-grid-vertical::after {
            display: none;
        }
    }
}

// Teaser Starpage List element
.teaser-ce-startpage-list {
    .card-title {
        margin-top: scale.px-to-rem(15);
        font-size: scale.fluid-size(18, 28, 576, 1642);
    }

    .card-text p {
        margin-bottom: 0;
    }

    .card-eyebrow:has(.card-eyebrow-text:empty) {
        display: none;
    }

    .teaser-ce-startpage-list-buttons {
        display: flex;
        flex-direction: column;
        margin-top: scale.px-to-rem(40);
    }

    .button {
        height: scale.px-to-rem(40);
    }
}

@include media-breakpoint-down(md) {
    .teaser-ce-startpage-list {
        .row {
            gap: scale.px-to-rem(32);
        }

        .card-title {
            height: auto;
        }

        .button {
            max-width: scale.px-to-rem(180);
        }

        .teaser-link-s-logo {
            width: scale.px-to-rem(22);
            height: scale.px-to-rem(22);
        }
    }
}

@include media-breakpoint-up(md) {
    .teaser-ce-startpage-list {
        .row {
            row-gap: scale.px-to-rem(40);
        }

        .box-grid-horizontal::after {
            display: none;
        }

        .teaser-ce-startpage-list-buttons {
            display: flex;
            flex-direction: row;
            gap: scale.px-to-rem(40);
            margin-top: scale.px-to-rem(80);
        }

        .button {
            height: scale.px-to-rem(70);
        }
    }
}

@include media-breakpoint-up(lg) {
    .teaser-ce-startpage-list, .teaser-ce-focus-small {
        .box-grid:nth-child(even):not(:nth-child(4n)) .box-grid-vertical::after {
            display: none;
        }
    }
}

// "claim content" element
.ticker-item {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: scale.px-to-rem(42);
    letter-spacing: normal;
    white-space: nowrap;
    will-change: transform;

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(42, 150, 576, 1642);
    }
}

.ticker {
    width: 100%;
    overflow: hidden;
}

.employees, .search-results {
    @include media-breakpoint-up(md) {
        padding: scale.px-to-rem(30) 0 scale.px-to-rem(30) 0;
    }

    .facets {
        padding-top: scale.px-to-rem(30);

        @include media-breakpoint-up(md) {
            padding-top: 0;
        }
    }
}

.search-results .facets {
    > fieldset:not(:first-child) {
        .facet-radio label {
            font-weight: 400;
        }
    }
}

.employees-list {
    h2 {
        margin: 0 0 scale.px-to-rem(20) 0;
        border-bottom: styles.border-thin(1, var(--secondary-color));
        padding-bottom: scale.px-to-rem(20);
        font-size: var(--font-size-large);
        font-weight: 700;

        @include media-breakpoint-up(md) {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

.employees-list-item-links {
    margin-top: scale.px-to-rem(20);
    border-left: styles.border-thin(1, var(--secondary-color));

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    a {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between !important;
        width: 100%;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        span {
            display: block;
        }

        .icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.employees-list-item-function {
    display: block;

    p { // stylelint-disable-line no-descending-specificity
        margin: 0;
        font-weight: 700;
    }

    strong:not(.employees-list-item-first-department) {
        display: inline-block;
        margin-top: scale.px-to-rem(10);
    }
}

.employees-list-item-departments {
    font-size: var(---font-size-small);
    font-weight: 300;
    letter-spacing: normal;
}

.employee-detail {
    border-top: styles.border-thin(1, var(--secondary-color));
    padding-top: scale.px-to-rem(20);

    .employees-list-item-links {
        margin-top: scale.px-to-rem(20);
    }

    .employee-email {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between !important;
        width: 100%;

        a {
            display: block;
            width: initial;
        }

        i {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

// "project stories" elements
.project-stories-accordion {
    .p-accordion-tab {
        border-bottom-color: var(--secondary-color);

        &:first-child {
            border-top-color: var(--secondary-color);
        }
    }

    .p-accordion-header {
        font-size: scale.fluid-size(20, 48, 576, 1642);

        .icon {
            width: scale.px-to-rem(17);
            height: scale.px-to-rem(20);

            @include media-breakpoint-up(md) {
                width: scale.px-to-rem(32);
                height: scale.px-to-rem(38);
            }
        }
    }

    .p-accordion-header-text {
        line-height: 1.2;
    }
}

// "spotlight" element
.spotlight-ce {
    .button {
        margin-bottom: scale.px-to-rem(47);
    }
}

.spotlight-wrapper {
    display: flex;
    flex-direction: column;
}

.spotlight-image-mobile {
    order: -1;
    width: 100%;
    height: auto;
    margin-bottom: scale.px-to-rem(25);
}

// minimum width that Ukiyo (parallax effect library) works with scale=2 and speed=3
// for an image with a height of 1400px and 700px visible area at maximum screen size
@media (min-width: 1024px) {
    .spotlight-ce {
        h2 {
            margin: 0 0 scale.fluid-size(20, 30, 1024, 1642) 0;
            font-size: scale.fluid-size(40, 60, 1024, 1642);
        }

        .button {
            margin-bottom: 0;

            &:hover {
                background-color: #fff;
            }
        }
    }

    .spotlight-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-top: 3%;
        padding-bottom: 10%;
        overflow-y: hidden;
        aspect-ratio: 1920 / 700;
    }

    .segment.spotlight-content {
        position: relative;
        z-index: 1;
        padding-right: 8%;
        padding-left: 51%;
    }

    .spotlight-image-mobile {
        display: none;
    }

    .spotlight-image-desktop-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
    }

    .spotlight-image-desktop {
        width: 100%;
        height: auto;
    }

    .spotlight-image-desktop-cone {
        position: absolute;
        bottom: 57%;
        left: 40%;
        height: 160%;
        color: var(--secondary-color);
        opacity: 0.95;
    }
}

// "spotlight small" elements

.spotlight-small-wrapper {
    padding-top: 3%;
    padding-bottom: 5%;
}

.spotlight-small-cone-wrapper {
    display: none;
}

@media (min-width: 1024px) {
    .spotlight-small-ce {
        position: relative;

        h2 {
            margin: 0;
            font-size: scale.fluid-size(40, 60, 1024, 1642);
        }

        .button-cta {
            color: var(--primary-color-text);

            &:hover {
                color: var(--secondary-color-text);
            }
        }

        &.scheme-dark .button-cta {
            color: var(--secondary-color-text);

            &:hover {
                background-color: #fff;
            }
        }
    }

    .spotlight-small-wrapper {
        display: flex;
    }

    .spotlight-small-cone-wrapper {
        position: absolute;
        display: block;
        padding: 0;
        overflow: hidden;
        aspect-ratio: 5/1;
        inset: 0;
    }

    .spotlight-small-desktop-cone {
        position: absolute;
        bottom: 35%;
        left: 55%;
        height: 260%;
        rotate: 3deg;
    }

    .spotlight-small-content {
        position: relative;
        z-index: 1;
        flex-basis: 50%;
    }

    .spotlight-small-content-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

@include media-breakpoint-up(xl) {
    .spotlight-small-ce {
        min-height: 216px;
    }
}

@include media-breakpoint-up(xxxl) {
    .spotlight-small-ce {
        position: relative;
        overflow: hidden;
    }
}

// infobox element

.infobox-ce-content {
    position: relative;
    padding: scale.px-to-rem(32);

    @include media-breakpoint-up(md) {
        padding: scale.px-to-rem(40) scale.px-to-rem(32);
    }

    .infobox-ce-light & {
        border: styles.border-thin($color: var(--secondary-color));
    }

    .infobox-ce-medium & {
        @include styles.color-scheme(tertiary);
    }

    .infobox-ce-dark & {
        @include styles.color-scheme(primary);
    }

    h2 {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}

.infobox-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}

.news-content-elements {
    .infobox-ce-content {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

// "async search" elements

.funding-programs-list, .news-list, .publication-list, .employees-list, .event-calendar, .program-tracks-list-with-images, .search-list {
    --bs-gutter-y: var(--bs-gutter-x);
}

.faq-list, .news-list, .publication-list, .event-calendar, .search-list {
    margin-bottom: 2em;
}

#event-calendar {
    background-color: var(--tertiary-color);

    input[type='date'] {
        border-radius: 30px;
        padding: 0.3rem 0.9rem;
        background-color: #fff;
    }

    li::before {
        content: initial;
    }
}

.event-calendar-filter {
    > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: scale.px-to-rem(20);

        @include media-breakpoint-up(lg) {
            justify-content: space-between;
        }
    }

    input {
        margin-left: scale.px-to-rem(24);

        @include media-breakpoint-up(lg) {
            margin-left: scale.px-to-rem(6);
        }
    }
}

.event-calendar-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: scale.px-to-rem(8);
    background: #fff;
    box-shadow: 0 0 scale.px-to-rem(15) var(--color-light);

    @include media-breakpoint-up(md) {
        border-radius: scale.px-to-rem(24);
    }

    &:hover {
        box-shadow: 0 0 scale.px-to-rem(15) var(--color-dark);
    }
}

.event-calendar-information {
    order: 1;
    margin: 0;
    border-bottom: scale.px-to-rem(2) solid var(--color-light);

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        overflow: hidden;
    }

    > div {
        position: relative;

        &::before,
        &::after {
            @include media-breakpoint-up(md) {
                position: absolute;
                z-index: 1;
                background-color: var(--color-light);
                content: '';
            }
        }

        &::after {
            @include media-breakpoint-up(md) {
                block-size: scale.px-to-rem(2);
                inline-size: 100vw;
                inset-block-start: -0.5rem;
                inset-inline-start: 0;
            }
        }

        &::before {
            @include media-breakpoint-up(md) {
                block-size: 100vh;
                inline-size: scale.px-to-rem(2);
                inset-block-start: 0;
                inset-inline-start: -0.5rem;
            }
        }
    }
}

.event-calendar-date {
    border-bottom: scale.px-to-rem(2) solid var(--color-light);
    padding: scale.px-to-rem(12);
    font-size: scale.px-to-rem(20);

    @include media-breakpoint-up(md) {
        border-bottom: 0;
        padding: scale.px-to-rem(34) scale.px-to-rem(15);
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        font-size: scale.px-to-rem(28);
    }

    @include media-breakpoint-up(xxl) {
        font-size: scale.px-to-rem(30);
    }
}

.event-calendar-location {
    gap: scale.px-to-rem(10);

    i.icon {
        margin-right: 0;
    }

    &:has(span + span) {
        flex-wrap: wrap;
    }
}

.event-calendar-day-and-location {
    padding-right: 0;
    padding-left: 0;
    font-size: var(--font-size-small);

    @include media-breakpoint-up(md) {
        font-size: var(--font-size);
    }

    > div:first-child {
        border-bottom: scale.px-to-rem(2) solid var(--color-light);

        @include media-breakpoint-up(md) {
            border-bottom: none;
        }
    }

    > div:last-child {
        @include media-breakpoint-up(md) {
            position: relative;
        }

        &::after {
            @include media-breakpoint-up(md) {
                position: absolute;
                z-index: 1;
                background-color: var(--color-light);
                content: '';
                block-size: scale.px-to-rem(2);
                inline-size: 100vw;
                inset-block-start: 0;
                inset-inline-start: -0.5rem;
            }
        }
    }

    > div {
        padding: scale.px-to-rem(20) scale.px-to-rem(10) scale.px-to-rem(20) scale.px-to-rem(10);
    }
}

.event-calendar-title {
    order: 2;
    padding: scale.px-to-rem(12) scale.px-to-rem(10);

    @include media-breakpoint-up(md) {
        padding: scale.px-to-rem(34) scale.px-to-rem(20);
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.event-calendar-internal-icon {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.event-calendar-weekday, .event-calendar-location {
    flex-direction: row;
    flex-wrap: nowrap;
}

.search-list-item {
    flex: 0 0 auto;
    width: 100%;

    .card {
        display: flex;
        flex-flow: column;
    }

    .card-title { // stylelint-disable-line no-descending-specificity
        order: 2;
        height: 1.4em;
        margin-top: scale.px-to-rem(10);
    }

    .card-eyebrow { // stylelint-disable-line no-descending-specificity
        &:not(.card-category) {
            order: 3;

            @include layout.line-clamp(2);
        }

        &.card-category {
            justify-content: space-between;
            order: 1;
        }
    }
}

.publication-item {
    text-decoration: none;

    &:hover {
        @include styles.color-scheme(tertiary);
        margin: 0;
        border: styles.border-thin();
        padding: calc(var(--bs-gutter-y) * 0.5);

        .box-list-vertical, .box-list-horizontal {
            &::before, &::after {
                content: initial;
            }
        }
    }
}

.pointer {
    cursor: pointer;
}

.job-list {
    border-top: styles.border-thin();

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    p { // stylelint-disable-line no-descending-specificity
        margin: 2em 0;
    }
}

.job-item {
    border-bottom: styles.border-thin();

    h3 {
        margin: 0 0 scale.px-to-rem(7) 0;
        font-weight: normal;

        @include media-breakpoint-up(md) {
            margin: 0 0 scale.px-to-rem(18) 0;
        }
    }

    .container-content {
        padding-top: scale.px-to-rem(20);

        @include media-breakpoint-up(md) {
            padding-bottom: scale.px-to-rem(20);
        }
    }

    .row > div {
        padding-bottom: scale.px-to-rem(10);

        &::after {
            display: block;
            border-bottom: styles.border-thin(1, var(--secondary-color));
            padding: 0 calc(var(--bs-gutter-x) * 0.5) scale.px-to-rem(10) calc(var(--bs-gutter-x) * 0.5);
            content: '';
        }

        &:last-child::after {
            border-bottom: none;
            content: none;
        }

        @include media-breakpoint-up(md) {
            padding-top: scale.px-to-rem(10);
            padding-bottom: scale.px-to-rem(10);

            &::after {
                border-bottom: none;
                content: none;
            }
        }
    }
}

.job-item, .event-calendar-item {
    .icon { // stylelint-disable-line no-descending-specificity
        flex-shrink: 0;
        width: scale.px-to-rem(20);
        height: scale.px-to-rem(20);
        margin-right: scale.px-to-rem(10);

        @include media-breakpoint-up(md) {
            width: scale.px-to-rem(23);
            height: scale.px-to-rem(23);
        }
    }
}

.spell-checking-suggestions button {
    border: 0;
    padding-right: 0;
    background-color: initial;
    font-style: italic;

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.filter-bar {
    border-top: styles.border-thin();
    padding: scale.px-to-rem(10) 0;
    background-color: var(--secondary-color);

    .search-field {
        display: flex;
        padding: 0.3rem 0.9rem;
    }

    .icon-search {
        width: scale.px-to-rem(24);
        stroke: var(--primary-color);
    }

    input {
        width: 100%;
    }

    .search-field-submit:hover {
        background-color: #fff;

        &::before {
            content: initial;
        }

        &::after {
            clip-path: initial;
        }
    }

    sl-select {
        width: 100%;
        border-radius: 30px;

        &::part(combobox) {
            background-color: var(--secondary-color);
        }

        &::part(display-input) {
            color: var(--text-color);
        }
    }
}

.facets {
    sl-select {
        --sl-color-neutral-50: var(--tertiary-color);
        --sl-color-neutral-200: var(--primary-color);
        --sl-color-neutral-800: var(--text-color);

        max-width: 80%;

        margin: scale.px-to-rem(30) 0;

        &::part(combobox) {
            border: styles.border-thin();
            border-radius: 30px;
        }

        &::part(display-input) {
            &::placeholder {
                color: currentColor;
                opacity: 1; // required for Firefox
            }

            &:placeholder-shown {
                text-overflow: ellipsis;
            }
        }

        &::part(listbox) {
            max-height: scale.px-to-rem(300);
            border-radius: unset;
        }

        &::part(form-control-label) {
            @include visually-hidden();
        }
    }

    sl-option {
        color: var(--primary-color);
        background-color: var(--tertiary-color);

        &::part(base) {
            color: currentColor;
            background-color: transparent;

            &:hover {
                background-color: var(--secondary-color);
            }
        }

        &[aria-selected='true']::part(base) {
            @include styles.color-scheme(primary);
        }
    }
}

// "tasks & activities" element
.tasks-list {
    @include media-breakpoint-down(md) {
        &.inner-row {
            margin-right: 0;
            margin-left: 0;

            .tasks-list-item {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    @include media-breakpoint-up(md) {
        --bs-gutter-y: var(--bs-gutter-x);
    }

    .card-eyebrow { // stylelint-disable-line no-descending-specificity
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }

    .card-eyebrow-icon {
        width: calc(var(--font-size-large) * 1.5);
        height: calc(var(--font-size-large) * 1.5);

        @include media-breakpoint-up(md) {
            margin-bottom: 1em;
        }
    }

    .card-eyebrow-text {
        margin-bottom: 0;
        text-transform: none;

        @include media-breakpoint-down(md) {
            padding: 1em;
            font-size: var(--font-size-large);
            letter-spacing: unset;

            $size: scale.px-to-rem(23);

            &::after {
                @include styles.icon-base($size, $size);
                @include styles.icon-text-color('arrow', 90deg);
                position: absolute;
                top: 1em;
                right: 0;
            }

            a {
                text-decoration: none;

                &:hover, &:active {
                    text-decoration: underline max(1px, 0.05em);
                }
            }
        }
    }

    .card-title { // stylelint-disable-line no-descending-specificity
        @include media-breakpoint-up(md) {
            margin-bottom: 1em;
        }
    }
}

.tasks-list-item {
    cursor: pointer;

    @include media-breakpoint-down(md) {
        padding: 0;

        &.box-grid-top-closed {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &.box-grid {
            .box-grid-vertical::after {
                display: none;
            }
        }

        &:first-child .box-grid-horizontal::before {
            border-top: none;
        }

        &.box-grid-top-closed {
            .box-grid-horizontal::before {
                display: none;
            }
        }

        .box-grid-horizontal::after {
            border-bottom: none;
        }
    }
}

// "Seven-Steps" elements

.seven-steps-items {
    margin: scale.px-to-rem(50) 0;
}

.seven-steps-item {
    display: flex;
    flex-direction: column;
    border-bottom: styles.border-thin(1, var(--secondary-color));
    padding: scale.px-to-rem(20);

    &:first-child {
        border-top: styles.border-thin(1, var(--secondary-color));
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.seven-steps-info-left {
    display: inline-flex;
    flex: 50%;
    flex-direction: row;
    cursor: pointer;

    &:focus-visible {
        @include styles.focus-keyboard;
    }

    h3 {
        @include typography.hyphenate();
        flex: 3;
        margin: scale.px-to-rem(5) 0 scale.px-to-rem(5) scale.px-to-rem(20);
        font-size: scale.fluid-size(25, 38, 576, 1642);
        font-weight: 400;
        line-height: 1.1;
    }

    @include media-breakpoint-up(md) {
        padding-right: scale.px-to-rem(30);
    }

    @include media-breakpoint-up(xl) {
        h3 {
            margin-top: 0;
        }
    }
}

.seven-steps-icon-container {
    position: relative;
    padding: scale.px-to-rem(18);

    @include media-breakpoint-up(md) {
        padding: scale.px-to-rem(22);
    }
}

.seven-steps-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.seven-steps-icon-image {
    @include styles.icon-base(100%, 100%);
}

.seven-steps-info-right {
    flex: 50%;
    margin-top: scale.px-to-rem(15);
    margin-left: scale.px-to-rem(15);
    border-left: styles.border-thin(1, transparent);
    padding-left: scale.px-to-rem(40);

    p { // stylelint-disable-line no-descending-specificity
        margin-bottom: 0;
    }

    .button {
        margin-top: scale.px-to-rem(15);
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 0;
        border-left: styles.border-thin(1, var(--secondary-color));

        .button {
            margin-top: scale.px-to-rem(30);
        }
    }
}

.seven-steps-subheader {
    font-size: scale.fluid-size(20, 28, 576, 1642);
    overflow-wrap: break-word;
}

.seven-steps-item-bodytext {
    @include typography.hyphenate();
    font-size: scale.fluid-size(14, 20, 576, 1642);
    font-weight: 100;
}

// Funds Universe Element

.funds-teaser-text {
    margin: 0;
}

.funds-items {
    display: flex;
    flex-direction: column;
    gap: scale.px-to-rem(30);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: scale.px-to-rem(410);
    margin-bottom: scale.px-to-rem(80);

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: scale.px-to-rem(50);
        min-height: scale.px-to-rem(710);
    }
}

.funds-item {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: scale.px-to-rem(15);
    background-color: var(--secondary-color);
    box-shadow: 0 0 scale.px-to-rem(25) #afd0e7;
    transition: all 0.5s ease;

    @include media-breakpoint-up(lg) {
        width: auto;
    }
}

.funds-top-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    border-radius: scale.px-to-rem(15) scale.px-to-rem(15) 0 0;
    transition: all 0.5s ease;

    h3 {
        margin-bottom: 0;
        padding: scale.px-to-rem(20) scale.px-to-rem(20) 0 scale.px-to-rem(20);
        color: var(--primary-color);
        font-size: scale.px-to-rem(30);
        transition: all 0.5s ease;

        @include media-breakpoint-up(sm) {
            padding: scale.px-to-rem(20) scale.px-to-rem(40) 0 scale.px-to-rem(40);
            font-size: scale.px-to-rem(35);
        }

        @include media-breakpoint-up(xl) {
            font-size: scale.px-to-rem(45);
        }
    }
}

.funds-image-container {
    display: flex;
    align-items: center;
    height: scale.px-to-rem(200);
    margin: scale.px-to-rem(50) 0;

    @include media-breakpoint-up(sm) {
        height: scale.px-to-rem(420);
    }
}

.funds-image {
    height: 100%;
    margin: auto;
}

.funds-bottom-content {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    border-radius: 0 0 scale.px-to-rem(15) scale.px-to-rem(15);
    padding: scale.px-to-rem(20);
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.5s ease;

    .button { // stylelint-disable-line no-descending-specificity
        visibility: hidden;
    }

    .icon-arrow-right {
        display: block;
    }

    @include media-breakpoint-up(sm) {
        align-items: flex-start;
        padding: scale.px-to-rem(26);

        .icon-arrow-right {
            display: none;
        }

        .button {
            margin-bottom: 0;
            visibility: visible;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: scale.px-to-rem(40);
    }
}

.funds-item-text {
    height: 0;
    margin-bottom: 0;
    color: transparent;

    @include media-breakpoint-up(sm) {
        height: auto;
        margin-bottom: scale.px-to-rem(30);
    }
}

.funds-item:hover, .funds-item:focus-within {
    height: 100%;
    background-color: var(--primary-color);

    h3 {
        color: var(--secondary-color);
    }

    .funds-bottom-content {
        justify-content: right;
        background-color: transparent;

        @include media-breakpoint-up(sm) {
            background-color: #fff;
        }
    }

    .funds-item-text {
        height: 0;
        margin-bottom: 0;
        color: transparent;

        @include media-breakpoint-up(sm) {
            height: auto;
            margin-bottom: scale.px-to-rem(30);
            color: var(--primary-color);
        }
    }

    .icon-arrow-right {
        color: var(--secondary-color);
    }
}

.funding-programs-list {
    margin-bottom: scale.px-to-rem(30);

    @include media-breakpoint-up(md) {
        margin-bottom: scale.px-to-rem(50);
    }

    a:hover {
        text-decoration: none;
    }

    &.inner-row {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: calc(var(--bs-gutter-x) * -0.5);
        }
    }
}

.funding-programs-list-facets {
    sl-select {
        max-width: initial;

        &::part(tag__base) {
            border-color: transparent;
            background-color: transparent;
        }

        &::part(display-input)::placeholder {
            color: var(--primary-color);
        }

        &::part(tag__remove-button__base) {
            color: var(--primary-color);
        }

        @include media-breakpoint-down(md) {
            margin: scale.px-to-rem(15) 0;
        }
    }
}

.funding-programs-list-item, .search-list-item.with-link, .news-list .teaser-list-item {
    &:hover {
        margin: 0;
        border: styles.border-thin();
        padding: calc(var(--bs-gutter-y) * 0.5);
        cursor: pointer;

        @include styles.color-scheme(tertiary);

        .box-grid-vertical, .box-grid-horizontal {
            &::before, &::after {
                content: initial;
            }
        }
    }
}

.funding-programs-list-item {
    .box-grid-vertical {
        height: 100%;
    }

    .box-grid-horizontal {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .card-title { // stylelint-disable-line no-descending-specificity
        order: 2;
        border-bottom: styles.border-thin(1, var(--secondary-color));
    }

    .card-eyebrow {
        order: 1;
        margin-top: 0;
    }

    .card-text { // stylelint-disable-line no-descending-specificity
        order: 3;
        margin-top: scale.px-to-rem(15);

        @include media-breakpoint-up(md) {
            margin-top: scale.px-to-rem(25);
        }

        ul {
            margin: 0;
        }
    }

    .list-links {
        strong {
            margin-right: 0.5rem;
        }

        .icon {
            align-self: baseline;
        }
    }
}

.container-program-tracks {
    border-top: styles.border-thin(1, var(--primary-color));
    border-bottom: styles.border-thin(1, var(--primary-color));
}

.program-tracks-list-with-images {
    cursor: pointer;

    &:nth-child(1) {
        .box-grid-horizontal::before {
            content: none;
        }
    }

    .card-image {
        order: 0;
    }
}

.program-track-image {
    width: 100%;
    height: auto;
    border-radius: scale.px-to-rem(15);
}

.program-track-with-image-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: scale.px-to-rem(15);

    .button {
        display: none;
    }

    h3 {
        margin-bottom: 0;
        font-size: scale.px-to-rem(25);
        font-weight: 300;
    }

    @include media-breakpoint-up(md) {
        flex-direction: column;
        align-items: flex-start;

        .button {
            display: inline-flex;
            margin-top: scale.px-to-rem(15);
            margin-bottom: 0;
            margin-left: -0.8em;
            border: 0;

            &:hover {
                background-color: transparent;
            }
        }

        .program-track-link-mobil {
            display: none;
        }
    }

    @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: center;

        .button {
            margin-top: 0;
        }
    }
}

.program-tracks-list-with-icons {
    margin: scale.px-to-rem(80) 0;
}

.program-track-with-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: styles.border-thin(1, var(--secondary-color));
    padding: scale.px-to-rem(20) 0;

    &:first-child {
        border-top: styles.border-thin(1, var(--secondary-color));
    }

    @include media-breakpoint-up(md) {
        padding: scale.px-to-rem(20);
    }
}

.program-track-icon-container {
    display: flex;
    margin-right: scale.px-to-rem(15);

    @include media-breakpoint-up(sm) {
        margin-right: scale.px-to-rem(20);
        border: styles.border-thin(1, var(--primary-color));
        border-radius: 100%;
        padding: scale.px-to-rem(15);
    }

    @include media-breakpoint-up(lg) {
        padding: scale.px-to-rem(25);
    }
}

.program-track-icon {
    width: scale.px-to-rem(40);
    height: scale.px-to-rem(40);
}

.program-track-with-icon-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .button {
        display: none;
    }

    h3 {
        margin-bottom: 0;
        font-size: scale.px-to-rem(22);
        font-weight: 300;
    }

    @include media-breakpoint-up(sm) {
        .button {
            display: inline-flex;
            margin-bottom: 0;
        }

        .program-track-link-mobil {
            display: none;
        }

        h3 {
            margin-right: scale.px-to-rem(5);
            font-size: scale.px-to-rem(25);
        }
    }

    @include media-breakpoint-up(lg) {
        h3 {
            font-size: scale.px-to-rem(35);
        }
    }
}

.program-track-with-icon-title {
    font-size: scale.px-to-rem(40);
}

.news-list {
    scroll-margin-block-start: scale.px-to-rem(70);

    .card-eyebrow .card-tag {
        position: static;
        order: 2;
        min-width: fit-content;
        margin-left: auto;
        background-color: var(--secondary-color);
    }

    .card-text {
        padding-top: scale.px-to-rem(30);
    }
}

.board-content {
    figcaption::before {
        content: none;
    }

    p { // stylelint-disable-line no-descending-specificity
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        figure {
            margin-bottom: 0;
        }
    }
}

.frame.frame-type-container-project-stories {
    @include media-breakpoint-up(md) {
        margin-top: scale.px-to-rem(60);
    }
}

.frame-space-before-extra-small, .frame-space-before-small, .frame-space-before-medium, .frame-space-before-large, .frame-space-before-extra-large {
    display: inline-block;
}

.frame-space-before-extra-small {
    margin-top: 1em;
}

.frame-space-before-small {
    margin-top: 2em;
}

.frame-space-before-medium {
    margin-top: 3em;
}

.frame-space-before-large {
    margin-top: 4em;
}

.frame-space-before-extra-large {
    margin-top: 5em;
}

.image-lightbox-wrapper {
    position: relative;
}
