/*
 * Plain HTML elements in content area
 *
 * DO NOT ADD ANY CSS CLASSES TO THIS FILE!!!
 *
 * TODO: move the contents of this file to its proper place (within a proper file of directory "content")
 */

@use 'sass:math';
@use 'utilities/scale';
@use 'utilities/styles';
@use 'utilities/typography';
@use 'bootstrap/scss/functions';

// stylelint-disable no-invalid-position-at-import-rule
.content {
    @import 'content/html';
    @import 'content/swiper';
}

// Select the possibly first h2 element after a header-bar and apply a margin top
// See content/_html for h2 styles
.header-bar + .segment.content h2 {
    @include media-breakpoint-down(sm) {
        // Same margin top on mobile as h1 without header images
        margin: scale.px-to-rem(18) 0 scale.px-to-rem(10) 0;
    }
}

@import 'content/typo3';
// stylelint-enable no-invalid-position-at-import-rule

:is(p,div).teaser {
    @include typography.hyphenate();

    margin: 0 0 scale.px-to-rem(23) 0;
    font-size: scale.px-to-rem(20);
    letter-spacing: normal;

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(20, 34, 576, 1642);
    }

    @include media-breakpoint-up(md) {
        margin: 0 0 scale.px-to-rem(88) 0;
    }
}

figure.video {
    @include media-breakpoint-up(md) {
        margin-bottom: scale.px-to-rem(49);
    }

    figcaption {
        margin: scale.px-to-rem(11) 0 scale.px-to-rem(11) 0;
    }
}

.video-wrapper {
    position: relative;
    max-width: 100%;
    border-radius: $image-border-radius;
    padding-bottom: 56.25%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // UserCentrics
    .uc-embedding-container {
        position: absolute;
        max-height: 100%;
    }

    &.ucdisplay {
        position: relative;
        min-height: 320px;
        padding-bottom: 0;
    }
}

.accordion {
    margin-bottom: scale.px-to-rem(3);

    @include media-breakpoint-up(md) {
        margin-bottom: scale.px-to-rem(63);
    }
}

.p-accordion-tab {
    border-bottom: styles.border-thin();
    padding: scale.px-to-rem(16) 0;

    &:first-child {
        border-top: styles.border-thin();
    }
}

.p-accordion-header {
    font-size: var(--font-size-large);

    @include media-breakpoint-up(md) {
        font-size: 140%; // equals 28px for xxl (20px)
    }

    .p-accordion-header-link {
        justify-content: space-between;
    }

    .icon {
        width: scale.px-to-rem(11);
        height: scale.px-to-rem(13);

        @include media-breakpoint-up(md) {
            width: scale.px-to-rem(21);
            height: scale.px-to-rem(25);
        }
    }

    .icon-arrow-down-dark {
        background-image: url('../icons/fwf/arrow_down_dark.svg');
    }

    .icon-arrow-up-dark {
        background-image: url('../icons/fwf/arrow_up_dark.svg');
    }

    .p-accordion-toggle-icon {
        flex-shrink: 0;
        order: 1;
        margin-right: 0;
    }
}

.p-accordion-content {
    margin-top: scale.px-to-rem(30);
}

@include media-breakpoint-up(md) {
    .image-double-left {
        width: percentage(math.div(7, $grid-columns));
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .image-double-right {
        width: percentage(math.div(5, $grid-columns));
        margin-top: 20%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .image-equal-width {
        width: percentage(math.div(6, $grid-columns));
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
}

ul.list-links {
    padding: 0;
    list-style-type: none;

    > li {
        display: flex;
        padding-left: 0;

        > * {
            align-self: center;
        }

        &::before {
            content: none;
        }

        .icon {
            flex-shrink: 0;
            width: scale.px-to-rem(18);
            height: scale.px-to-rem(18);
            margin: scale.px-to-rem(3) 13px scale.px-to-rem(3) 0; // don't scale horizontally to safe screen space

            @include media-breakpoint-up(md) {
                width: scale.px-to-rem(24);
                height: scale.px-to-rem(24);
                margin: scale.px-to-rem(7) 16px scale.px-to-rem(7) 0;
            }
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .icon-link-download {
        @include styles.icon-text-color('download');
    }

    .icon-link-default, .icon-link-internal, .icon-link-external {
        @include styles.icon-text-color('link');
    }

    .icon-link-telephone {
        @include styles.icon-text-color('telephone');
    }

    .icon-link-email {
        @include styles.icon-text-color('mail');
    }

    .icon-link-scilog {
        @include styles.icon-text-color('scilog');
    }

    .icon-link-program {
        @include styles.icon-text-color('program');
    }
}

.side-column {
    align-self: start;
    width: 100%;
    border-left: styles.border-thin();
    padding-left: $grid-gutter-width-half;

    @include media-breakpoint-up(lg) {
        margin-left: $grid-gutter-width-half;
    }

    &.side-column-bottom {
        align-self: end;
    }

    &.side-column-empty {
        border-left: 0;
    }

    .frame {
        margin: 0 0 scale.px-to-rem(22) 0;
        border-bottom: styles.border-thin($color: var(--secondary-color));

        @include media-breakpoint-up(md) {
            margin: 0 0 scale.px-to-rem(31) 0;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    h2, h3, h4 {
        margin: 0 0 scale.px-to-rem(5) 0;
        font-weight: 500;

        @include media-breakpoint-up(md) {
            margin-bottom: scale.px-to-rem(12);
        }
    }

    p {
        margin-bottom: scale.px-to-rem(13);

        @include media-breakpoint-up(md) {
            margin-bottom: scale.px-to-rem(31);
        }
    }

    ul, ol {
        margin-bottom: scale.px-to-rem(13);

        @include media-breakpoint-up(md) {
            margin-bottom: scale.px-to-rem(31);
        }
    }

    h2, h3, h4, p, ul, ol {
        font-size: var(--font-size-small);
        letter-spacing: 0.02em;
    }

    ul {
        li {
            &::before {
                top: scale.px-to-rem(4);

                @include media-breakpoint-up(md) {
                    top: scale.px-to-rem(8);
                }
            }
        }
    }

    figure { // stylelint-disable-line no-descending-specificity
        @include media-breakpoint-up(md) {
            margin-bottom: scale.px-to-rem(31);
        }

        img {
            width: auto;
            max-width: 100%;
            height: auto;
            border-radius: unset;
        }
    }
}

.side-column-left {
    margin-left: $grid-gutter-width-half;

    @include media-breakpoint-up(md) {
        margin-left: 5px;
        padding-left: $grid-gutter-width-half;
    }
}

.side-column-social-media {
    margin-top: scale.px-to-rem(22);
    border-top: styles.border-thin($color: var(--secondary-color));
    padding-top: scale.px-to-rem(26);

    @include media-breakpoint-up(md) {
        margin-top: scale.px-to-rem(35);
        padding-top: scale.px-to-rem(40);
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0 scale.px-to-rem(16) scale.px-to-rem(16) 0;
            padding: 0;

            @include media-breakpoint-up(md) {
                margin: 0 scale.px-to-rem(19) scale.px-to-rem(19) 0;
            }

            &::before {
                content: none;
            }
        }
    }

    .icon-facebook {
        @include styles.icon-hover('facebook_dark');
    }

    .icon-linkedin {
        @include styles.icon-hover('linkedin_dark');
    }

    .icon-twitter {
        @include styles.icon-hover('twitter_dark');
    }
}

.social-media-block {
    margin-top: scale.px-to-rem(45);

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: scale.px-to-rem(20) scale.px-to-rem(15);
        margin: 0;
        padding: 0;
        list-style-type: none;

        @include media-breakpoint-up(xxl) {
            // Set max-width to the size of three icons next to each other
            // @see .icon class and the gap above
            max-width: calc((#{scale.px-to-rem(40)} + #{scale.px-to-rem(15)}) * 3);
        }
    }

    .icon-facebook {
        @include styles.icon-hover('facebook_light');
    }

    .icon-linkedin {
        @include styles.icon-hover('linkedin_light');
    }

    .icon-twitter {
        @include styles.icon-hover('twitter_light');
    }

    .icon-instagram {
        @include styles.icon-hover('instagram_light');
    }

    .icon-youtube {
        @include styles.icon-hover('youtube_light');
    }
}

.frame-type-html {
    iframe {
        width: 100%;
    }
}

.frame-type-container-award {
    h2 {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        border-bottom: styles.border-thin();
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 45px;
        }

        span {
            position: relative;
            display: block;
            flex-basis: 50%;
            flex-grow: 0;
            box-sizing: border-box;

            &:last-child {
                order: -1;
                margin-bottom: 20px;
                border-bottom: styles.border-thin();
                padding-bottom: 20px;
                font-size: scale.px-to-rem(60);
                letter-spacing: normal;

                @include media-breakpoint-up(sm) {
                    font-size: scale.fluid-size(60, 219, 576, 1642);
                }

                @include media-breakpoint-up(md) {
                    order: 2;
                    margin-bottom: 0;
                    border-bottom: none;
                    padding-bottom: 0;
                    text-align: right;
                }
            }

            @include media-breakpoint-up(md) {
                &:first-child {
                    padding-right: 20px;
                }

                &:last-child {
                    padding-left: 20px;
                }

                &:last-child::before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 70%;
                    background-color: currentColor;
                    transform: translate(-50%, -50%);
                    content: '';
                }
            }
        }
    }

    p.quote {
        @include media-breakpoint-up(md) {
            margin-bottom: scale.px-to-rem(50);
        }
    }
}

p.quote {
    position: relative;
    margin-bottom: scale.px-to-rem(20);
    margin-left: calc(var(--bs-gutter-x) * 0.5 * -1);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    font-size: scale.px-to-rem(20);
    letter-spacing: normal;

    &::before {
        position: absolute;
        top: 0.3rem;
        bottom: 0.2rem;
        left: 0;
        border-left: styles.border-thin();
        content: '';

        @include media-breakpoint-up(sm) {
            top: 0.5rem;
            bottom: 0.3rem;
        }
    }

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(20, 48, 576, 1642);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: scale.px-to-rem(30);
    }
}

.quote-author {
    display: block;
    margin-top: scale.px-to-rem(25);
    font-size: var(--font-size-small);

    &::before {
        margin-right: scale.px-to-rem(15);
        content: '\2022';
    }

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(14, 28, 576, 1642);
    }

    @include media-breakpoint-up(lg) {
        letter-spacing: normal;
    }
}

// scrollable table (in RTE fields, see TypoScript)
.content-table {
    overflow-x: auto;
}

.small {
    font-size: var(--font-size-small);
}

.segment:has(.frame-type-text):has(+ .teaser-ce-startpage-list) {
    .frame-type-text {
        p {
            @include media-breakpoint-up(lg) {
                font-size: scale.px-to-rem(34);
                line-height: scale.px-to-rem(44);
            }
        }

        &,
        p:last-of-type {
            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }
    }
}

.frame-type-text:has([data-teaser-link]) {
    cursor: pointer;
}

.text-media-wrapper-flex {
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: scale.px-to-rem(32);
    }

    &:not(:has(.image-lightbox-wrapper)) figure,
    .image-lightbox-wrapper {
        @include media-breakpoint-up(lg) {
            flex: 1 1 33.33%;
        }
    }

    .text {
        @include media-breakpoint-up(lg) {
            flex: 1 1 66.66%;
        }
    }
}

.text-media-wrapper-image-right {
    @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
    }
}

.text-media-wrapper-image-float-left:not(:has(.image-lightbox-wrapper)) figure,
.text-media-wrapper-image-float-left .image-lightbox-wrapper {
    @include media-breakpoint-up(lg) {
        float: left;
        margin-right: scale.px-to-rem(32);
    }
}

.text-media-wrapper-image-float-right:not(:has(.image-lightbox-wrapper)) figure,
.text-media-wrapper-image-float-right .image-lightbox-wrapper {
    @include media-breakpoint-up(lg) {
        float: right;
        margin-left: scale.px-to-rem(32);
    }
}

.text-media-wrapper {
    @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.text-media-wrapper:not(:has(.image-lightbox-wrapper)) figure,
.text-media-wrapper .image-lightbox-wrapper {
    @include media-breakpoint-up(lg) {
        width: 33.33%;
        margin-bottom: scale.px-to-rem(24);
    }

    &:has(figcaption) {
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}

:is(p).small-font {
    font-family: 'tiempos', serif;
    font-size: 1rem;
}
