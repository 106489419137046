@use '../utilities/scale';
@use '../utilities/styles';

.swiper-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-wrapper {
        width: 100%;
        height: 100%;

        .swiper-slide {
            width: 100%;
            height: 100%;

            img {
                width: 100vw;
                height: 100vh;
                object-fit: contain;
            }
        }
    }

    .swiper-pagination {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    .swiper-controls {
        position: absolute;
        right: 0;
        bottom: 10px;
        left: 29px;
        z-index: 3;
        width: scale.px-to-rem(120);
        margin-bottom: 20px;
        pointer-events: auto; // grab events to make buttons work

        @include media-breakpoint-up(md) {
            bottom: 20px;
        }

        button {
            margin-right: scale.px-to-rem(14);
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: #fff;
        transform: translateY(-50%);
    }

    .swiper-scrollbar {
        display: none;
    }
}
