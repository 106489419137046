@use '../utilities/scale';
@use '../utilities/styles';

.skip-links-wrapper {
    max-height: 0;
    margin-left: scale.px-to-rem(1); // hide left border when box touches viewport
    background-color: var(--secondary-color);

    &:focus-within {
        max-height: 8rem;
        overflow: hidden;
        transition: max-height 0.5s;
    }
}

@media (prefers-reduced-motion) {
    .skip-links-wrapper {
        transition: none;
    }
}

.skip-links {
    padding: 0 29px;
    font-size: scale.px-to-rem(16);
    line-height: 2;

    @include media-breakpoint-up(sm) {
        display: inline-block;
        border: styles.border-thin();
        padding: scale.px-to-rem(16);
        font-size: scale.px-to-rem(24);
        box-shadow: scale.px-to-rem(5) 0 scale.px-to-rem(5) -#{scale.px-to-rem(5)} currentColor;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    @include media-breakpoint-up(sm) {
        li {
            margin-left: scale.px-to-rem(20);
            border-left: styles.border-thin();
            padding-left: scale.px-to-rem(20);

            &:first-child {
                margin-left: 0;
                border-left: none;
                padding-left: 0;
            }
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            outline-offset: scale.px-to-rem(8);
        }
    }

    .icon {
        width: 1.5em;
        height: 1.5em;
    }
}

.skip-links-inner {
    margin-bottom: scale.px-to-rem(15);
}
