@use '../utilities/layout';
@use '../utilities/scale';
@use '../utilities/styles';
@use '../utilities/typography';

/*

┌────────────────────────┐
│                    Tag │
│  Image                 │
│                        │
└────────────────────────┘

 Eyebrow Icon+Text

 TITLE
 =====

 Subtitle

 Text

 ----------------------------

 Logical order (for a11y):
 * Title
 * Subtitle
 * Eyebrow (text above title)
 * Image
 * Text

*/

.card-title {
    @include layout.line-clamp(2);

    height: 2.7em;
    overflow-wrap: break-word;
}

.card-tag {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    border-radius: var(--sl-border-radius-pill);
    padding: 0.3em 0.8em;
    color: var(--text-color);
    background-color: #fff;
    font-size: var(--font-size-small);
}

.card-eyebrow {
    display: flex;
    align-items: center;
    margin-top: scale.px-to-rem(18);
    font-size: var(--font-size-small);
}

.card-eyebrow-icon {
    width: var(--font-size-large);
    height: var(--font-size-large);
    margin-right: 0.5em;
}

.card-eyebrow-text {
    @include text-truncate();
    @include typography.uppercase();
    text-wrap: wrap;
}

.card-image {
    img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: $image-border-radius;
    }
}

.card-image-placeholder {
    border-radius: $image-border-radius;
    background-color: var(--primary-color);
    aspect-ratio: 3 / 2;
}

.card-link {
    .button {
        margin-bottom: 0;
    }
}

.card-title, .card-with-text .card-text {
    font-size: var(--font-size-large);

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(18, 34, 576, 1642);
    }

    @include media-breakpoint-up(md) {
        letter-spacing: normal;
    }

    a {
        text-decoration: none;

        &:hover, &:active { // stylelint-disable-line no-descending-specificity
            text-decoration: underline;
        }
    }
}

.card-subtitle {
    @include layout.line-clamp(4);
}

.card {
    position: relative;

    .card-title, .card-text {
        h3 {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
    }
}

.card-with-text {
    display: flex;
    height: 100%;
    border-radius: $image-border-radius;
    padding: 1.9em 1.4em;
    background-color: var(--primary-color);

    .card-text {
        @include layout.line-clamp(5);

        margin-top: auto;
        color: var(--primary-color-text);
    }
}

.card-with-image {
    display: flex;
    flex-direction: column;

    .card-title {
        order: 3;
    }

    .card-eyebrow {
        order: 2;
    }

    .card-subtitle {
        order: 4;
    }

    .card-image {
        order: 1;
    }

    &.card-wide {
        .card-title {
            font-size: scale.px-to-rem(27);

            @include media-breakpoint-up(md) {
                font-size: scale.fluid-size(27, 60, 768, 1642);
            }
        }

        .card-text {
            order: 4;
            padding-right: 1.5em;
            font-size: var(--font-size-small);
        }

        .card-link {
            order: 5;
        }
    }
}

.card-box-list {
    display: flex;
    flex-direction: column;

    .card-title { // stylelint-disable-line no-descending-specificity
        order: 2;
    }

    .card-eyebrow {
        order: 3;
    }

    .card-eyebrow-category {
        order: 1;
    }
}

.card-s-logo {
    position: absolute;
    top: scale.px-to-rem(16);
    right: scale.px-to-rem(16);
    z-index: 1;
}

@include media-breakpoint-up(md) {
    .card-with-image {
        &.card-wide {
            display: grid;
            grid-template-rows: auto auto 1fr auto;
            grid-template-columns: 43.5% 1fr;

            > * {
                order: unset;
            }

            .card-title {
                grid-row: 2;
            }

            .card-eyebrow {
                grid-row: 1;
                margin-top: 0;
            }

            .card-text {
                grid-row: 3;
                margin-top: 0;
            }

            .card-link {
                grid-row: 4;
            }

            .card-image {
                grid-row: 1 / span 4;
                grid-column: 2;
            }
        }
    }
}

.card-image-news-video {
    position: relative;
}

.card-image-news-video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40%;
    height: 40%;
    color: #ffff;
    transform: translate(-50%, -50%);
}

.card-dark-scheme {
    background-color: var(--secondary-color);

    p, a {
        color: var(--primary-color);
    }
}

.card-employee {
    .card-text {
        order: 4;

        a {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            span {
                display: block;
            }

            .icon {
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 0.5rem;
            }
        }
    }
}
