@use '../utilities/scale';
@use '../utilities/styles';

@import '~swiper/scss/zoom';

/*
 * Styles for Swiper in the content area incl. for CE elements
 */

.swiper {
    position: relative;
    margin-bottom: scale.px-to-rem(20);

    @include media-breakpoint-up(md) {
        margin-bottom: scale.px-to-rem(80);
    }

    &.swiper-initialized {
        figcaption {
            max-width: 75%;
        }
    }

    .button-cta::after {
        display: none;
    }
}

.swiper-wrapper {
    width: auto;
    height: auto;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
    position: relative;
    left: 0;
    width: 100%;
    background: none;

    &::before {
        position: absolute;
        top: 50%;
        z-index: 0;
        width: 100%;
        height: scale.px-to-rem(1);
        background-color: currentColor;
        content: '';
    }
}

.swiper-scrollbar-drag {
    position: relative;
    z-index: 2;
}

.swiper-slide {
    height: auto;

    figure {
        margin-bottom: 0;
    }
}

.swiper-zoom-wrapper {
    border-radius: scale.px-to-rem(12);
    overflow: hidden;
}

.swiper-controls {
    display: flex;

    button {
        margin-bottom: 0;
        margin-left: 0.6em;
    }

    @include media-breakpoint-up(md) {
        &.swiper-mobile-only {
            display: none;
        }
    }
}

.swiper-scrollbar + .swiper-controls {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin-bottom: 20px;
}

.button-cta:has(+ .swiper-controls:not(.swiper-mobile-only)) {
    &::after {
        display: none;
    }
}

// content elements

.frame-type-image {
    @include media-breakpoint-up(md) {
        .swiper-mobile-only {
            .swiper-wrapper {
                margin-right: calc(-0.5 * var(--bs-gutter-x));
                margin-left: calc(-0.5 * var(--bs-gutter-x));
            }
        }

        .swiper-controls, .swiper-scrollbar {
            display: none;
        }
    }
}

.frame-type-video-slider {
    .swiper-wrapper {
        padding-bottom: 5rem;
    }

    @include media-breakpoint-up(md) {
        .swiper-controls {
            display: flex;
        }

        .swiper-scrollbar {
            display: block;
        }
    }
}

.teaser-ce-content {
    .swiper {
        overflow: visible;

        &:not(.swiper-mobile-only) .card-eyebrow .card-tag {
            position: static;
            order: 2;
            min-width: fit-content;
            margin-left: auto;
            background-color: var(--secondary-color);
        }
    }

    .swiper-slide {
        border-left: styles.border-thin();
        padding-right: 18px; // ~ 2/3 of horizontal padding (mobile)
        padding-left: 18px;

        > div {
            height: 100%;
        }

        &:last-child {
            border-right: styles.border-thin();
        }
    }

    &.teaser-ce-wide {
        .swiper-slide {
            border-left: none;
            padding-right: 0;

            &:last-child {
                border-right: none;
            }

            &:not(.swiper-slide-active) {
                .card > *:not(.card-image) {
                    visibility: hidden;

                    @include media-breakpoint-up(md) {
                        visibility: visible;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .teaser-ce-content {
        .swiper-slide {
            border: none;
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            padding-left: 0;

            &:last-child::after {
                position: absolute;
                top: 0;
                right: calc(var(--bs-gutter-x) * 0.5);
                width: 0;
                height: 100%;
                border-right: styles.border-thin();
                content: '';
            }

            &:last-child {
                border-right: 0;
            }

            &.box-grid-vertical {
                &::before {
                    left: calc(var(--bs-gutter-x) * -0.5);
                }

                &:last-child::after {
                    right: calc(var(--bs-gutter-x) * 0.5);
                }

                .card {
                    margin-right: calc(var(--bs-gutter-x) * 0.5);

                    &::after {
                        display: none;
                    }
                }
            }
        }

        &.teaser-ce-large,
        &.teaser-ce-small {
            .swiper-slide {
                padding-right: 0;

                &.box-grid-vertical:last-child::after {
                    right: 0;
                }
            }
        }

        &.teaser-ce-wide {
            .swiper-slide {
                padding-left: var(--bs-gutter-x);

                &:last-child::after {
                    border-right: none;
                }
            }
        }
    }
}
