@use '../utilities/scale';
@use '../utilities/styles';

.search-field {
    @include styles.pill($padding: 0.4em 0.8em);

    border: styles.border-thin(1);

    input[type='search'] {
        margin: -2px 0;
        border-color: transparent;
        padding: 2px 5px 3px;

        &:focus {
            border-color: currentColor;
            outline: none;
        }
    }

    .icon-search {
        flex: 0 0 auto;
        width: scale.px-to-rem(21);

        @include media-breakpoint-up(md) {
            width: scale.px-to-rem(23);
        }
    }
}

.search-field-submit {
    $size: scale.px-to-rem(23);

    position: relative;
    flex: 0 0 auto;
    width: $size;
    aspect-ratio: 1;

    // the icon
    &::after {
        @include styles.icon-base($size, $size);
        @include styles.icon-text-color('arrow_circled', 90deg);
        position: absolute;
        top: 0;
        left: 0;
    }

    &:hover {
        // the background colour of the icon
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: $size;
            border-radius: 50%;
            background-color: var(--secondary-color);
            background-clip: content-box;
            content: '';
            aspect-ratio: 1;
        }

        &::after {
            color: var(--primary-color);
            clip-path: circle(calc($size * 0.5 - 1px));
        }
    }
}
