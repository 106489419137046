@use '../utilities/scale';
@use '../utilities/styles';
@use '../utilities/typography';
@use '../utilities/layout';

.scroll-element {
    display: none;

    @include media-breakpoint-up(sm) {
        position: fixed;
        top: 70%;
        right: scale.px-to-rem(30);
        z-index: 1000;
        display: block;
        justify-content: normal;
        margin: 0;
        cursor: pointer;

        &:focus {
            outline: none;
            outline-offset: 0;
        }

        &:focus-visible {
            @include styles.focus-keyboard();
        }
    }
}

.scroll-progress-indicator-wrapper {
    width: scale.px-to-rem(45);
    height: scale.px-to-rem(45);
    border-radius: 50%;
    background-color: var(--color-light);
    outline: scale.px-to-rem(2) solid var(--primary-color);

    @include media-breakpoint-up(sm) {
        position: relative;
        width: scale.px-to-rem(65);
        height: scale.px-to-rem(65);
        border-radius: 0;
        background-color: transparent;
        outline: none;
    }
}

.scroll-progress-indicator, .scroll-progress-icon-container {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @include media-breakpoint-up(sm) {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s ease;
    }
}

.scroll-progress-indicator {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.scroll-progress-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(sm) {
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 95%;
        height: 95%;
        background: #fff;
        transform: translate(-50%, -50%);
    }
}

svg.scroll-arrow {
    height: auto;
    transition: all 0.2s ease;
    fill: none;
    stroke: var(--primary-color);

    @include media-breakpoint-up(sm) {
        width: 80%;
    }

    @include media-breakpoint-up(md) {
        stroke-width: 1;
    }
}

.scroll-element-mobile-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: scale.px-to-rem(25) scale.px-to-rem(40);

    @include media-breakpoint-up(sm) {
        display: none;
    }

    a {

        margin-bottom: 0;
    }

    i {
        width: scale.px-to-rem(45);
        height: scale.px-to-rem(45);
    }
}

.icon-scroll {
    background-image: url('../icons/fwf/scroll-element.svg');
}
