@use 'sass:map';
@use 'utilities/scale';
@use 'utilities/styles';
@use 'utilities/typography';

@import 'bootstrap/scss/mixins';

footer {
    padding: scale.px-to-rem(16) 0;
    font-size: scale.px-to-rem(14);

    @include media-breakpoint-up(md) {
        font-size: scale.px-to-rem(20);
    }

    @include media-breakpoint-up(xxl) {
        padding-bottom: scale.px-to-rem(12);
    }

    h2 {
        @include typography.uppercase();

        margin-bottom: scale.px-to-rem(26);
        font-size: scale.px-to-rem(14);
        font-weight: 500;

        @include media-breakpoint-up(md) {
            font-size: scale.px-to-rem(18);
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        display: block;
        padding: 0;
        list-style-type: none;
    }
}

.footer-content {
    > .row {
        > div {
            @include typography.hyphenate();

            margin-bottom: scale.px-to-rem(19);

            @include media-breakpoint-up(xxl) {
                border-right: styles.border-thin();
            }
        }

        > :nth-child(3) {
            border-right: styles.border-thin();
        }

        > :nth-child(4) {
            padding: 0 scale.px-to-rem(23);
        }

        > :last-child {
            border-right: none;
        }
    }
}

.footer-form {
    border-bottom: styles.border-thin();
    padding-bottom: 1em;

    @include media-breakpoint-up(md) {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.footer-claim {
    margin-bottom: scale.px-to-rem(21) !important;
    border-bottom: styles.border-thin();
    padding: scale.px-to-rem(15) 0 scale.px-to-rem(4) $grid-gutter-width-half;
    font-size: scale.px-to-rem(30);
    letter-spacing: normal;

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(30, 45, 576, 1642);
    }

    @include media-breakpoint-up(md) {
        border-bottom: none;
    }

    p {
        margin-bottom: scale.px-to-rem(25);
    }
}

.footer-pages {
    border-top: styles.border-thin();
    padding-top: scale.px-to-rem(15);

    @include media-breakpoint-up(xxl) {
        padding-top: scale.px-to-rem(10);
        font-size: scale.px-to-rem(18);

        .container-page {
            position: relative;
        }
    }

    nav {
        @include segment();
        padding-right: 19px; // less padding so increased text-size or text-spacing does not force word-breaks

        padding-bottom: scale.px-to-rem(12);

        @include media-breakpoint-up(xxl) {
            margin: scale.px-to-rem(3) 0 0 0;
            padding-bottom: 0;
        }

        ul {
            @include media-breakpoint-up(xxl) {
                display: flex;
                justify-content: space-between;
                margin: 0;
            }
        }

        li { // stylelint-disable-line no-descending-specificity
            @include typography.hyphenate();

            display: block;
            margin-top: scale.px-to-rem(10);

            &.footer-pages-separator {
                display: none;
            }

            &.footer-pages-copyright-measurement {
                display: none;
            }

            @include media-breakpoint-up(xxl) {
                margin: 0 auto;
                padding-right: 0;
                padding-bottom: scale.px-to-rem(5);

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.footer-pages-separator {
                    display: block;
                    margin: 0;
                    border-left: styles.border-thin();
                }

                &.footer-pages-copyright-measurement {
                    display: block;
                    white-space: nowrap;
                    visibility: hidden;
                }
            }

            a {
                @include media-breakpoint-up(xxl) {
                    display: block;
                }
            }
        }
    }
}

.footer-copyright {
    @include segment();
    @include typography.hyphenate();

    border-top: styles.border-thin();
    padding-top: scale.px-to-rem(13);
    font-size: scale.px-to-rem(12);

    @include media-breakpoint-up(md) {
        font-size: scale.px-to-rem(18);
    }

    @include media-breakpoint-up(xxl) {
        position: absolute;
        top: 0;
        right: 0;
        border-top: none;
        padding-top: 0;
        padding-left: 0;
    }
}
