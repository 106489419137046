@mixin segment() {
    // don't scale with font, so we don't waste screen space
    padding-right: 29px;
    padding-left: 29px;

    @include media-breakpoint-up(md) {
        padding-right: 59px;
        padding-left: 59px;
    }

    @include media-breakpoint-up(xl) {
        padding-right: 139px;
        padding-left: 139px;
    }
}

@mixin line-clamp($lines) {
    display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
    overflow: hidden;
    -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
    -webkit-line-clamp: $lines;
}

// This is a copy of Bootstrap mixin make-grid-columns()
// Required for a nested grid with a number of columns that when multiplied will not make up the number of columns if the outer grid.
@mixin make-inner-grid-columns($columns, $outer-columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    // plan2net: sanity check
    @if ($columns >= $outer-columns) {
        @error 'Inner grids must have less columns then (outer) grids.';
    }

    @each $breakpoint in map-keys($breakpoints) {
        // plan2net: this is the only change - provide a new "namespace"
        $infix: '-' + $columns + breakpoint-infix($breakpoint, $breakpoints); // stylelint-disable-line scss/function-no-unknown

        // plan2net: no more changes below

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            // Provide basic `.col-{bp}` classes for equal-width flexbox columns
            .col#{$infix} {
                flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
            }

            .row-cols#{$infix}-auto > * {
                @include make-col-auto();
            }

            @if $grid-row-columns > 0 {
                @for $i from 1 through $grid-row-columns {
                    .row-cols#{$infix}-#{$i} {
                        @include row-cols($i);
                    }
                }
            }

            .col#{$infix}-auto {
                @include make-col-auto();
            }

            @if $columns > 0 {
                @for $i from 1 through $columns {
                    .col#{$infix}-#{$i} {
                        @include make-col($i, $columns);
                    }
                }

                // `$columns - 1` because offsetting by the width of an entire row isn't possible
                @for $i from 0 through ($columns - 1) {
                    @if not ($infix == '' and $i == 0) { // Avoid emitting useless .offset-0
                        .offset#{$infix}-#{$i} {
                            @include make-col-offset($i, $columns);
                        }
                    }
                }
            }

            // Gutters
            //
            // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
            @each $key, $value in $gutters {
                .g#{$infix}-#{$key},
                .gx#{$infix}-#{$key} {
                    --#{$prefix}gutter-x: #{$value};
                }

                .g#{$infix}-#{$key},
                .gy#{$infix}-#{$key} {
                    --#{$prefix}gutter-y: #{$value};
                }
            }
        }
    }
}
