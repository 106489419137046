@use 'scale';

$animation-focus-visible: focus-visible-in;

@mixin focus-keyboard() {
    outline: scale.px-to-rem(4) solid currentColor;
    outline-offset: 0.25em;

    @media screen and (prefers-reduced-motion: no-preference) {
        animation: 0.5s ease $animation-focus-visible;
    }
}

@mixin focus() {
    &:focus-visible {
        @include focus-keyboard();
    }
}

@mixin focus-input() {
    &:focus {
        outline: scale.px-to-rem(4) solid currentColor;
        outline-offset: 0.25em; // adjust offset with the font size

        @media screen and (prefers-reduced-motion: no-preference) {
            animation: 0.5s ease $animation-focus-visible;
        }
    }
}

@mixin animated-link() {
    transition: text-decoration-thickness 0.2s;
    text-decoration-thickness: max(1px, 0.05em);
    text-underline-offset: 0.1em;

    &:hover, &:active {
        text-decoration-thickness: max(1px, 0.15em);
    }
}

/*
 * expects two multi-colour SVGs
 */
@mixin icon-hover($image, $hoverSuffix: '_hover', $path-prefix: 'fwf') {
    --icon-image: url('../icons/#{$path-prefix}/#{$image}.svg');
    background-image: var(--icon-image);
    transition: background 0.2s ease-in-out;

    &:hover {
        background-image: url('../icons/#{$path-prefix}/#{$image}#{$hoverSuffix}.svg');
    }

    // preload image
    &::after {
        position: absolute;
        z-index: -1;
        width: 0;
        height: 0;
        overflow: hidden;
        content: url('../icons/#{$path-prefix}/#{$image}_hover.svg');
    }
}

/*
 * expects a single black SVGs (will appear in text colour)
 */
@mixin icon-text-color($mask-image, $angle: 0) {
    background-color: currentColor;
    mask-image: url('../icons/#{$mask-image}.svg');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;

    @if $angle != 0 {
        rotate: $angle;
    }
}

@mixin color-scheme($scheme) {
    color: var(--#{$scheme}-color-text);
    background-color: var(--#{$scheme}-color);
}

@mixin table-border() {
    position: absolute;
    right: 0;
    display: block;
    overflow: hidden;
    background: currentColor;
    content: '';
}

@mixin icon-base($width, $height) {
    display: inline-block;
    width: $width;
    height: $height;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: middle;
    content: '';
}

@mixin pill($padding: 0.5em 0.8em) {
    display: inline-flex;
    gap: 0.5em;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--sl-border-radius-pill);
    padding: $padding;
    line-height: 1;
    white-space: nowrap;
    text-decoration: none;
}

@function border-thin($width: 1, $color: currentColor) {
    $border-color: #{$color};

    @if $color == transparent {
        $border-color: transparent;
    }

    @return scale.px-to-rem($width) solid $border-color;
}
