@use '../utilities/scale';

.breadcrumbs {
    flex: 1;
}

.breadcrumbs-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    column-gap: scale.px-to-rem(8);
    list-style: none;
}

.breadcrumbs-list-item {
    display: flex;
}

.breadcrumbs-link {
    margin-right: scale.px-to-rem(8);
}

@include media-breakpoint-up(lg) {
    .breadcrumbs-list {
        column-gap: scale.px-to-rem(16);
    }

    .breadcrumbs-link {
        margin-right: scale.px-to-rem(16);
    }
}
