/*
 * Common cross-browser styles aka reset.css or reboot.css
 */

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(#fff, 0); // Change the default tap highlight to be completely transparent in iOS (taken from Bootstrap).
    text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS (taken from Bootstrap).
}

a {
    color: inherit;

    &[aria-disabled='true'] {
        cursor: not-allowed;
    }
}

figure {
    margin: 0;
}

// works on check boxes and radio buttons
input {
    accent-color: inherit;
}

input, textarea, sl-textarea::part(textarea), select, optgroup {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

textarea {
    resize: vertical;
}

// Hide spinners
input[type='number'] {
    // stylelint-disable-next-line property-no-vendor-prefix
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
    }
}

input:not([type]), input[type='text'], input[type='email'], input[type='tel'], input[type='url'], input[type='search'], input[type='number'], input[type='date'], input[type='datetime-local'], textarea, select {
    border: 0.1em solid currentColor;
    padding: 0.9em 1.2em;
    color: inherit; // for Safari
    background: none;

    &::placeholder {
        color: currentColor;
        opacity: 1; // required for Firefox
    }

    &:placeholder-shown {
        text-overflow: ellipsis;
    }
}

fieldset {
    margin: 0;
    border: none;
    padding: 0;
}

button, input[type='button'], input[type='reset'], input[type='submit'], input[type='file']::file-selector-button {
    margin: 0;
    border-radius: 0;
    padding: 0 0.5em;
    color: inherit; // for Safari
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    appearance: button; // Correct the inability to style clickable types in iOS and Safari (taken from Boostrap).

    &::-moz-focus-inner {
        border: none;
    }
}

input[type='file']::file-selector-button {
    margin-right: 1em;
}

button, input[type='button'], input[type='reset'], input[type='submit'] {
    &:disabled {
        cursor: not-allowed;
    }
}

input[type='file']:disabled::file-selector-button {
    cursor: default;
}

ol, ul, dl {
    margin: 0 0 1em 0;
}

p {
    margin: 0 0 1em;
}

iframe {
    border: 0;
}

table {
    border-collapse: collapse;
    caption-side: top;
}

caption {
    color: inherit;
    text-align: center;
}

th {
    font-weight: inherit;
    text-align: inherit; // same as td
    text-align: -webkit-match-parent; // for Safari
}

thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
