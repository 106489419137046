.lightbox-fullscreen {
    .image-gallery-controls > {
        button.image-gallery-controls-close.fullscreen-only {
            display: flex;
        }

        button.image-gallery-controls-fullscreen {
            display: none;
        }
    }

    img {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
    }
}
