@use '../utilities/scale';
@use '../utilities/styles';
@use '../utilities/typography';

.page-header-none {
    margin-top: $header-bar-height-xs;

    @include media-breakpoint-up(md) {
        margin-top: $header-bar-height-md;
    }
}

.page-header-small, .page-header-big {
    position: relative;

    .segment {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        max-height: 100vh;
        color: #fff;
    }

    &.page-header-dark {
        .segment {
            color: var(--text-color);
        }
    }

    .container-content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        height: 100%;
    }
}

.page-header-title {
    h1 {
        @include typography.hyphenate();

        font-weight: 400;
        line-height: 1.1;
        text-rendering: geometricPrecision;

        @include media-breakpoint-up(md) {
            line-height: 1;
        }
    }
}

.page-header-text {
    display: flex;
    flex-direction: column;
    margin-top: $header-bar-height-xs;

    @include media-breakpoint-up(md) {
        margin-top: $header-bar-height-md;
    }

    .page-header-title {
        header {
            margin: scale.px-to-rem(18) 0 scale.px-to-rem(15) 0;

            @include media-breakpoint-up(md) {
                margin: scale.px-to-rem(75) 0;
            }
        }

        h1 {
            margin: 0;
            font-size: scale.px-to-rem(33);
            letter-spacing: normal;

            @include media-breakpoint-up(md) {
                margin: 0;
                font-size: scale.fluid-size(33, 90, 576, 1642);
            }
        }

        .teaser {
            margin: scale.px-to-rem(18) 0 0 0;

            @include media-breakpoint-up(md) {
                margin: scale.px-to-rem(29) 0 0 0;
            }
        }
    }
}

.page-header-small {
    .page-header-title {
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-bottom: 85px;
        }

        h1 {
            margin: 0;
            font-size: scale.px-to-rem(42);
            letter-spacing: normal;

            @include media-breakpoint-up(md) {
                font-size: scale.fluid-size(42, 90, 576, 1642);
            }
        }
    }
}

.page-header-big {
    .segment { // stylelint-disable-line no-descending-specificity
        pointer-events: none; // let events pass through to allow swiping
    }

    .swiper {
        .container-content {
            align-content: flex-end;
            padding-bottom: 44px;

            // justify-content: flex-end;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
                padding-bottom: 90px;
            }
        }
    }

    .page-header-title {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        h1 {
            order: 2;
            margin: scale.px-to-rem(20) 0 0 0;
            font-size: scale.px-to-rem(42);
            letter-spacing: normal;
            pointer-events: auto; // grab events to allow selecting text

            @include media-breakpoint-up(sm) {
                font-size: scale.fluid-size(42, 90, 576, 1642);
            }
        }

        p {
            @include typography.uppercase();

            margin-bottom: 0;
            font-size: scale.px-to-rem(15);

            @include media-breakpoint-up(md) {
                font-size: scale.px-to-rem(18);
            }
        }
    }
}

#page-header-big-light-cone path {
    /*

    To edit the clip-path copy the value of the CSS path property into the d attribute of the SVG path element.
    Modify the SVG with an editor and copy the new path back into the CSS.

    Warning:
    The view box must be 1x1 to allow for scaling (clipPathUnits="objectBoundingBox").
    This will make the light cone look distorted in the editor!

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1" width="1" height="1">
      <path d="..." />
    </svg>

     */

    // portrait
    d: path('m 5.1340811,-0.30112203 c 0.8356545,-0.47553156 0.8349058,-1.24611147 -0.0019,-1.72101137 -0.8244229,-0.4700553 -2.1700062,-0.4751101 -3.0056607,-0.011373 -0.00749,0.00421 -0.014977,0.00821 -0.022088,0.012425 -0.1969325,0.1118285 -0.3534309,0.2442937 -0.4605085,0.3898178 0,0 -1.52342199,2.08682035 -1.52454529,2.08871446 -0.0621487,0.0932962 -0.043054,0.21038889 0.108577,0.29694369 0.150882,0.0861351 0.3575492,0.0977179 0.52190946,0.0627585 0.00412,-6.0188e-4 3.69866943,-0.8560827 3.69866943,-0.8560827 0.2568364,-0.061283 0.4897117,-0.15036727 0.6855216,-0.2621939');

    @include media-breakpoint-up(sm) {
        // landscape
        d: path('m 2.1112131,-0.49167211 c 0.324379,-0.58166569 0.324088,-1.52409599 -7.47e-4,-2.10503429 -0.324839,-0.5809359 -0.851077,-0.5802769 -1.17545607,0.00133 -0.07641,0.1367923 -0.13708,0.2986607 -0.178712,0.4767062 0,0 -0.59140101,2.55253071 -0.59180601,2.55466908 -0.02418,0.1141602 -0.01676,0.2574191 0.04211,0.3632932 0.05854,0.1052747 0.138868,0.1195708 0.202649,0.076789 0.0016,-6.641e-4 1.43591208,-1.04723178 1.43591208,-1.04723178 0.09971,-0.0749126 0.190047,-0.1839045 0.266056,-0.32058065');
    }
}

.page-header-navigation {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-left: auto;
    pointer-events: auto; // grab events to make buttons work

    button {
        margin-left: scale.px-to-rem(14);
    }
}

.page-header-media {
    clip-path: url(#page-header-big-light-cone);
}

.page-header-background-media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.page-header-image {
    display: block;
    width: 100%;
    height: auto;
}

.page-header-video {
    width: 100%;
    font-size: 0; // Without this there's a small gap below the slides
    aspect-ratio: 9 / 16;

    @include media-breakpoint-up(sm) {
        aspect-ratio: 16 / 9;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@include media-breakpoint-up(sm) {
    .page-header-video-mobile {
        display: none;
    }

    .page-header-video-desktop {
        display: block;
    }
}
