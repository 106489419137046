/*
 * Unbound HTML elements in content area (no classes required for styling except look-alike elements)
 */

@use '../utilities/scale';
@use '../utilities/styles';
@use '../utilities/typography';

h2 {
    @include typography.hyphenate();

    margin: 0 0 scale.px-to-rem(15) 0;
    font-size: scale.px-to-rem(30);
    font-weight: 400;
    line-height: 1.05;
    letter-spacing: normal;
    text-rendering: geometricPrecision;

    @include media-breakpoint-up(sm) {
        margin: scale.fluid-size(30, 80, 576, 1642) 0 scale.fluid-size(10, 50, 576, 1642) 0;
        font-size: scale.fluid-size(30, 90, 576, 1642);
    }
}

h3 {
    @include typography.hyphenate();

    margin: 0 0 scale.px-to-rem(30) 0;
    font-size: var(--font-size-large);
    font-weight: 500;
    line-height: 1.1;

    @include media-breakpoint-up(sm) {
        font-size: scale.fluid-size(18, 28, 576, 1642);
        text-rendering: geometricPrecision;
    }

    @include media-breakpoint-up(md) {
        letter-spacing: normal;
    }
}

h4 {
    margin: 0 0 scale.px-to-rem(15) 0;
    font-size: var(--font-size);
    font-weight: 500;
    line-height: 1.1;
}

p {
    margin: 0 0 scale.px-to-rem(30) 0;
    line-height: 1.35;
}

ul, ol {
    margin: 0 0 scale.px-to-rem(30) 0;
    padding-left: 0;
    line-height: 1.35;
}

ul > li {
    position: relative;
    padding-left: scale.px-to-rem(12);
    list-style: none;

    @include media-breakpoint-up(md) {
        padding-left: scale.px-to-rem(18);
    }

    &::before {
        position: absolute;
        top: scale.px-to-rem(4);
        left: 0;
        font-size: 2rem;
        line-height: 0.5rem;
        vertical-align: middle;
        content: '·';

        @include media-breakpoint-up(md) {
            top: scale.px-to-rem(8);
        }
    }
}

ol {
    counter-reset: item;

    > li {
        position: relative;
        display: block;
        padding-left: scale.px-to-rem(30);

        &::before {
            position: absolute;
            left: 0;
            width: 2em;
            content: counter(item) '. ';
            counter-increment: item;
        }
    }
}

figure {
    margin-bottom: scale.px-to-rem(20);

    @include media-breakpoint-up(md) {
        margin-bottom: scale.px-to-rem(80);
    }

    img {
        width: 100%;
        height: auto;
        border-radius: scale.px-to-rem(12);
    }
}

figcaption {
    position: relative;
    margin: scale.px-to-rem(5) 0 scale.px-to-rem(31) 0;
    padding-left: scale.px-to-rem(12);
    font-size: var(--font-size-small);

    &::before {
        position: absolute;
        top: scale.px-to-rem(2);
        left: 0;
        font-size: 2rem;
        line-height: 0.5rem;
        vertical-align: middle;
        content: '·';

        @include media-breakpoint-up(md) {
            top: scale.px-to-rem(8);
        }
    }
}

$table-margin: scale.px-to-rem(20);

table, thead, tbody, th, tr, td {
    border: 0;
}

table {
    position: relative;
    width: calc(100% - 50px);
    margin-top: 60px;
    margin-left: $table-margin;
    border-collapse: collapse;
    padding: 30px;
    overflow-x: auto;

    caption {
        margin-bottom: 30px;
    }

    &::after {
        @include styles.table-border();

        bottom: 0;
        left: 0;
        height: scale.px-to-rem(1);
    }
}

th {
    position: relative;
    border-bottom: styles.border-thin();
    padding-bottom: scale.px-to-rem(16);
    font-weight: 700;

    &::before {
        @include styles.table-border();

        top: -$table-margin;
        left: 0;
        height: scale.px-to-rem(1);
    }
}

td {
    border-bottom: 1px solid var(--secondary-color);
    padding: scale.px-to-rem(16) 0;
    font-size: var(--font-size-small);
}

td, th {
    min-width: 6em;
    vertical-align: top;
}

thead, tbody {
    position: relative;

    &::before, &::after {
        @include styles.table-border();

        top: 0;
        bottom: 0;
        width: scale.px-to-rem(1);
    }

    &::before {
        left: -$table-margin;
    }

    &::after {
        right: -$table-margin;
    }
}

tbody {
    &::before, &::after {
        bottom: $table-margin;
    }

    tr:last-child {
        position: relative;

        td {
            border: 0;
        }
    }
}

hr {
    margin: 0 0 scale.px-to-rem(30) 0;
    border-style: solid;
    border-width: 0 0 scale.px-to-rem(1) 0;
}
