@use 'utilities/scale';
@use 'utilities/styles';
@use 'utilities/typography';

// stylelint-disable selector-class-pattern
.frame-type-form_formframework, .newsletter-form-wrapper, .filter-bar {
    label, legend, .legend {
        display: block;
        margin-bottom: 0.5rem;

        @include typography.hyphenate();

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            padding-top: 0.3rem;
        }
    }

    sl-textarea {
        &::part(textarea) {
            width: 100%;
            min-height: 8rem;
            border-radius: 15px;
            padding: 0.3rem 0.9rem;
            color: inherit; // for Safari
            background: none;
        }

        &::part(form-control-label) {
            @include visually-hidden();
        }
    }

    fieldset {
        border: none;
        padding: 0;
    }

    sl-select {
        --sl-color-neutral-50: var(--tertiary-color);
        --sl-color-neutral-200: var(--primary-color);
        --sl-color-neutral-800: var(--text-color);

        &::part(combobox) {
            border: styles.border-thin();
            border-radius: 30px;
        }

        &::part(display-input) {
            &::placeholder {
                color: currentColor;
                opacity: 1; // required for Firefox
            }

            &:placeholder-shown {
                text-overflow: ellipsis;
            }
        }

        &::part(listbox) {
            border-radius: unset;
        }

        &::part(form-control-label) {
            @include visually-hidden();
        }
    }

    sl-option {
        color: var(--primary-color);
        background-color: var(--tertiary-color);

        &::part(base) {
            color: currentColor;
            background-color: transparent;

            &:hover {
                background-color: var(--secondary-color);
            }
        }

        &[aria-selected='true']::part(base) {
            @include styles.color-scheme(primary);
        }
    }

    sl-checkbox {
        display: flex;

        &[checked]::part(checked-icon) {
            display: none;
        }

        &::part(control) {
            border: none;
            padding: scale.px-to-rem(10);
            background-image: url('../icons/unchecked.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            transition: background-image 0.3s ease;
        }

        &[checked]::part(control) {
            background-color: transparent;
            background-image: url('../icons/checked.svg');
            transition: background-image 0.3s ease;
        }

        &::part(label) {
            margin-left: scale.px-to-rem(10);
        }
    }

    sl-radio {
        &[checked]::part(checked-icon) {
            display: none;
        }

        &::part(control) {
            border: none;
            padding: scale.px-to-rem(10);
            background-image: url('../icons/unchecked.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            transition: background-image 0.3s ease;
        }

        &::part(control--checked) {
            background-color: transparent;
            background-image: url('../icons/checked.svg');
            transition: background-image 0.3s ease;
        }

        &::part(label) {
            margin-left: scale.px-to-rem(10);
        }
    }

    input:not([type]),
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='url'],
    input[type='search'],
    input[type='number'],
    input[type='date'],
    input[type='datetime-local'],
    textarea {
        width: 100%;
        border-radius: 30px;
        padding: 0.3rem 0.9rem;
    }

    textarea {
        min-height: 8rem;
    }

    .invalid-feedback {
        display: block;
        margin-top: 0.5em;
        color: var(--error-color);
    }

    .form-text {
        display: block;
        margin-top: 0.5em;
    }

    .form-navigation {
        display: inline-flex;
        gap: 0.5em;
        margin-top: 1em;
    }
}

.newsletter-form-wrapper {
    a {
        @include styles.animated-link();

        text-decoration-line: underline;
    }

    label, legend, .legend {
        margin-bottom: 0.5rem;
    }

    .button, input[type='file'].upload-button::file-selector-button {
        &:hover {
            border-color: var(--secondary-color);
        }
    }
}

.event-form-listbox {
    &::part(listbox) {
        max-height: scale.px-to-rem(300);
        border-radius: unset;
    }
}
