@font-face {
    src: url('../fonts/Aeonik-Regular.woff2') format('woff2'), url('../fonts/Aeonik-Regular.woff') format('woff');
    font-family: 'Aeonik';
    font-weight: 400;
    font-display: swap;
}

@font-face {
    src: url('../fonts/Aeonik-Medium.woff2') format('woff2'), url('../fonts/Aeonik-Medium.woff') format('woff');
    font-family: 'Aeonik';
    font-weight: 500;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/Aeonik-Bold.woff2') format('woff2'), url('../fonts/Aeonik-Bold.woff') format('woff');
    font-family: 'Aeonik';
    font-weight: 700;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/Aeonik-RegularItalic.woff2') format('woff2'), url('../fonts/Aeonik-RegularItalic.woff') format('woff');
    font-family: 'Aeonik';
    font-weight: 400;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/Aeonik-MediumItalic.woff2') format('woff2'), url('../fonts/Aeonik-MediumItalic.woff') format('woff');
    font-family: 'Aeonik';
    font-weight: 500;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/Aeonik-BoldItalic.woff2') format('woff2'), url('../fonts/Aeonik-BoldItalic.woff') format('woff');
    font-family: 'Aeonik';
    font-weight: 700;
    font-style: italic;
    font-display: fallback;
}
