@use 'sass:math';
@use '../utilities/scale';
@use '../utilities/styles';

@mixin button-icon-pill() {
    padding-top: 0.4em;
    padding-right: 0.6em;
    padding-bottom: 0.4em;
}

@mixin button-icon-base() {
    @include styles.icon-base(1.4em, 1.4em);
}

%button {
    @include styles.pill();

    margin-bottom: 1em;
    border: styles.border-thin(1);
    background: transparent;

    @media screen and (prefers-reduced-motion: no-preference) {
        transition: 0.2s ease-in-out;
    }

    @include media-breakpoint-up(md) {
        border: styles.border-thin(2);
    }

    &:hover {
        @include styles.color-scheme(secondary);
    }
}

.button {
    @extend %button;
}

// a placeholder element used for layout purposes
.button-spacer {
    border-color: transparent;
    padding-right: 0;
    padding-left: 0;
}

// semantic names (for independent changes in the future)
.button-back, .button-prev {
    @include button-icon-pill();

    &::before {
        @include button-icon-base();
        @include styles.icon-text-color('arrow_circled', -90deg);
    }
}

// semantic names (for independent changes in the future)
.button-cta, .button-forward, .button-next {
    @include button-icon-pill();

    &::after {
        @include button-icon-base();
        @include styles.icon-text-color('arrow_circled', 90deg);
    }
}

.button-reset {
    @include button-icon-pill();

    border-color: transparent;

    &:hover {
        border-color: currentColor;
        background-color: transparent;
    }

    &:focus {
        border-color: currentColor;
        border-style: dotted;

        &:hover {
            border-style: solid;
        }
    }

    &::before {
        @include button-icon-base();
        @include styles.icon-text-color('reset');

        width: 0.944em; // the icon is not square
    }
}

.button-no-border {
    border: none;

    &:hover {
        color: currentColor;
        background-color: transparent;
        text-decoration: underline;
    }
}

input[type='file'].upload-button {
    border: none;

    &::file-selector-button {
        @extend %button;
    }
}

/*
 * a button that encloses an icon
 */

.button-icon {
    border: none;
    border-radius: 50%; // this is required to make outline wrap around nicely
    padding: 0;
    background-color: transparent;
    line-height: 1;

    &:focus {
        outline-width: scale.px-to-rem(2);
    }

    &:disabled > .icon {
        &:hover {
            background-image: var(--icon-image);
        }
    }
}

/*
 * close button X
 */

.close-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 1rem;
    margin: 0;
    border: none;
    padding: 0;
    background: transparent;
}

.close-button-line {
    width: 1rem;
    min-height: scale.px-to-rem(1, 1);
    background-color: currentColor;
    transform-origin: center;
    transition: transform 0.5s ease;
}

.close-button-line:first-child {
    margin-bottom: 0.9rem;
}

.close-button[aria-pressed='true'] {
    .close-button-line {
        width: 1rem * math.sqrt(2);
    }

    .close-button-line:first-child {
        margin-bottom: 0;
        transform: rotate(45deg) translateY(1px);
    }

    .close-button-line:last-child {
        transform: rotate(-45deg) translateY(-1px);
    }
}
