@mixin hyphenate($minLength: 6, $minLengthBefore: 3, $minLengthAfter: 3) {
    word-break: break-word;
    hyphens: auto;
    -webkit-hyphenate-limit-after: #{$minLengthAfter}; // Safari
    -webkit-hyphenate-limit-before: #{$minLengthBefore}; // Safari
    hyphenate-limit-chars: #{$minLength} #{$minLengthBefore} #{$minLengthAfter}; // Chromium only
}

@mixin uppercase() {
    letter-spacing: 0.1em;
    text-transform: uppercase;
}
