@use '../utilities/styles';

// Swiper
$themeColor: var(--primary-color); // stylelint-disable-line scss/dollar-variable-pattern

@import 'swiper/swiper';
@import 'swiper/modules/scrollbar/scrollbar';

:root {
    --swiper-scrollbar-border-radius: #{$scrollbar-border-radius};
    --swiper-scrollbar-drag-bg-color: currentColor;
    --swiper-scrollbar-size: #{$scrollbar-size};
}

.swiper-no-swiping {
    cursor: text;
}

.swiper-button-play-pause[data-state='play'] .icon {
    @include styles.icon-hover('pause_circled');
}

.swiper-button-play-pause[data-state='pause'] .icon {
    @include styles.icon-hover('play_circled');
}
