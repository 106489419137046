@charset "utf-8";

@use 'sass:map';
@use 'sass:math';
@use 'utilities/scale';
@use 'utilities/styles';

$max-page-width: 1920px;
$max-content-width: 1642px;
$grid-gutter-width-px: 50;
$grid-gutter-width-half: scale.px-to-rem(math.div($grid-gutter-width-px, 2));
$scrollbar-size: scale.px-to-rem(5);
$scrollbar-border-radius: scale.px-to-rem(10);
$header-bar-content-height-xs: scale.px-to-rem(23);
$header-bar-content-height-md: scale.px-to-rem(31);
$header-bar-padding-vertical: scale.px-to-rem(19);
$header-bar-height-xs: $header-bar-content-height-xs + 2 * $header-bar-padding-vertical;
$header-bar-height-md: $header-bar-content-height-md + 2 * $header-bar-padding-vertical;
$image-border-radius: 12px;

// Bootstrap defaults
$grid-gutter-width: scale.px-to-rem($grid-gutter-width-px);

// Add custom breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1920px
);

// Minimal Boostrap setup incl. some SASS mixins and functions
@import 'bootstrap/scss/bootstrap-grid';

// SASS mixins and functions
@import 'bootstrap/scss/mixins/text-truncate';
@import 'bootstrap/scss/mixins/visually-hidden';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/utilities/api';
@import 'utilities/layout'; // needs Bootstrap grid mixins

// CSS rules
@import 'fonts';
@import 'normalise';
@import 'bootstrap/scss/helpers/visually-hidden';
@import 'widgets/icons';
@import 'widgets/box';
@import 'widgets/buttons';
@import 'widgets/page-header';
@import 'widgets/swiper';
@import 'widgets/swiper-fullscreen';
@import 'widgets/dialog';
@import 'widgets/claim';
@import 'widgets/card';
@import 'widgets/search';
@import 'widgets/scroll-element';
@import 'header';
@import 'footer';
@import 'lightbox';
@import 'image-gallery';
@import 'content';
@import 'forms';
@import '@shoelace-style/shoelace/dist/themes/light';
@import 'browser-update';

// used by utilities/styles.scss
@keyframes focus-visible-in {
    0% {
        outline-width: scale.px-to-rem(4);
    }

    60% {
        outline-width: scale.px-to-rem(6);
    }

    100% {
        outline-width: scale.px-to-rem(4);
    }
}

:root {
    @each $breakpoint in map-keys($grid-breakpoints) {
        --breakpoint-#{$breakpoint}: #{map.get($grid-breakpoints, $breakpoint)};
    }

    --breakpoint: xs;

    @include media-breakpoint-up(sm) {
        --breakpoint: sm;
    }

    @include media-breakpoint-up(md) {
        --breakpoint: md;
    }

    @include media-breakpoint-up(lg) {
        --breakpoint: lg;
    }

    @include media-breakpoint-up(xl) {
        --breakpoint: xl;
    }

    @include media-breakpoint-up(xxl) {
        --breakpoint: xxl;
    }

    --font-size-small: #{scale.px-to-rem(14)};
    --font-size: #{scale.px-to-rem(16)};
    --font-size-large: #{scale.px-to-rem(18)};

    @include media-breakpoint-up(md) {
        --font-size-small: #{scale.px-to-rem(15)};
        --font-size: #{scale.px-to-rem(17)};
        --font-size-large: #{scale.px-to-rem(19)};
    }

    @include media-breakpoint-up(lg) {
        --font-size-small: #{scale.px-to-rem(16)};
        --font-size: #{scale.px-to-rem(18)};
        --font-size-large: #{scale.px-to-rem(20)};
    }

    @include media-breakpoint-up(xl) {
        --font-size-small: #{scale.px-to-rem(17)};
        --font-size: #{scale.px-to-rem(19)};
        --font-size-large: #{scale.px-to-rem(21)};
    }

    @include media-breakpoint-up(xxl) {
        --font-size-small: #{scale.px-to-rem(18)};
        --font-size: #{scale.px-to-rem(20)};
        --font-size-large: #{scale.px-to-rem(22)};
    }
}

html {
    scroll-padding-top: $header-bar-height-xs + 1;

    @include media-breakpoint-up(md) {
        scroll-padding-top: $header-bar-height-md + 1.5;
    }
}

body {
    max-width: $max-page-width;
    margin: 0 auto;
    color: var(--text-color);
    background-color: #fff;
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: 1.4;
    letter-spacing: 0.02em;
    text-rendering: optimizeLegibility;
}

input:not([type]),
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='search'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
textarea,
sl-textarea::part(textarea),
select {
    border: styles.border-thin();
}

sl-textarea::part(base) {
    border: none;
}

a {
    text-decoration: underline;
}

a, button {
    @include styles.animated-link();
    @include styles.focus();
}

input:not([type]),
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='search'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='file'],
textarea,
sl-textarea::part(textarea),
sl-select,
sl-radio,
sl-checkbox {
    @include styles.focus-input();
}

sl-checkbox, sl-radio, sl-select, sl-textarea {
    --sl-focus-ring: 0 solid transparent;
    --sl-focus-ring-width: 0;
}

a[id] {
    border: scale.px-to-rem(180);
    scroll-margin-top: 118px;
}

.segment {
    @include segment();
}

.container-page {
    max-width: $max-page-width;
    margin: 0 auto;
}

.container-content {
    max-width: $max-content-width;
    margin: 0 auto;
}

.scheme-medium {
    @include styles.color-scheme(tertiary);
}

.scheme-medium-dark {
    @include styles.color-scheme(secondary);
}

.scheme-dark {
    @include styles.color-scheme(primary);
}

.scheme-mixed {
    background: linear-gradient(to bottom, var(--primary-color) 0, var(--primary-color) 50%, var(--secondary-color) 50%, var(--secondary-color) 100%);

    .upper-half {
        color: var(--primary-color-text);
    }

    .lower-half {
        color: var(--tertiary-color-text);
    }
}

// a stripped down version of Bootstrap .row for nested rows
.inner-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));

    > * {
        flex-shrink: 0;
        box-sizing: border-box;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
}

mark {
    color: var(--primary-color);
    background-color: var(--secondary-color);
}

@include make-inner-grid-columns(10);
@include make-inner-grid-columns(2);
