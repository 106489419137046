@use 'utilities/scale';
@use 'utilities/styles';

body {
    .buorg {
        border-color: var(--primary-color);
        border-width: scale.px-to-rem(1);
        border-radius: scale.px-to-rem(12);
        color: var(--text-color);
        background-color: var(--secondary-color);
        font-family: var(--font-family);
        font-size: var(--font-size);
        line-height: 1.4;
        letter-spacing: 0.02em;
        text-rendering: optimizeLegibility;
    }

    @media only screen and (max-width: 700px) {
        .buorg div {
            padding: scale.px-to-rem(20);
            line-height: 1.4;
        }
    }

    .buorg-pad {
        padding: scale.px-to-rem(20);
        line-height: 1.4;
        letter-spacing: 0.02em;
        text-rendering: optimizeLegibility;
    }

    .buorg-mainmsg, .buorg-moremsg {
        margin-bottom: scale.px-to-rem(26);
    }

    .buorg-buttons {
        text-align: left;
    }

    #buorgul, #buorgig {
        @include styles.pill();

        margin-bottom: 1em;
        border: styles.border-thin(1, var(--primary-color));
        border-color: var(--primary-color);
        color: var(--primary-color);
        background: var(--secondary-color);

        &:hover {
            @include styles.color-scheme(primary);
            border-color: var(--primary-color);
        }

        @media screen and (prefers-reduced-motion: no-preference) {
            transition: 0.2s ease-in-out;
        }

        @include media-breakpoint-up(md) {
            border: styles.border-thin(2);
        }
    }
}
