@use 'utilities/scale';
@use 'utilities/styles';

.image-gallery-image-info-wrapper {
    position: relative;
    max-width: 700px;
    height: 280px;
    margin: 10px 0 40px 0;
    border-radius: $image-border-radius;
    padding: 20px;
    background: #fff;

    @include media-breakpoint-up(md) {
        margin: 0;
    }
}

.image-gallery-image-info {
    height: 100%;
    padding-right: 30px;
    overflow-y: auto;
    font-size: scale.px-to-rem(18);

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        position: relative;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: var(--primary-color);
    }
}

.image-gallery-image-info-close {
    position: absolute;
    top: 20px;
    right: 3px;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    text-align: center;
    appearance: none;

    .icon {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: contain;
    }
}

.image-gallery-controls {
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: center;
    margin: 0 0 0 29px;
    color: var(--primary-color);
    font-size: scale.px-to-rem(12);

    @include media-breakpoint-up(md) {
        top: 20px;
        right: 20px;
        align-items: flex-start;
        font-size: scale.px-to-rem(18);
    }

    .icon {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: contain;
    }

    > button, > a {
        @include styles.pill();

        position: relative;
        justify-content: center;
        width: scale.px-to-rem(40);
        height: scale.px-to-rem(21);
        margin-left: 0.6em;
        border: none;
        background-color: #fff;

        &:focus {
            outline-color: #fff;
        }

        &.fullscreen-only {
            display: none;
        }

        &.image-gallery-controls-fullscreen {
            display: none;
        }

        &.image-gallery-controls-download {
            display: none;
        }

        &.image-gallery-controls-share {
            display: none;
        }

        &.image-gallery-controls-info {
            display: none;
        }

        @include media-breakpoint-up(md) {
            width: scale.px-to-rem(70);
            height: scale.px-to-rem(40);

            &.image-gallery-controls-fullscreen {
                display: block;

                span {
                    @include visually-hidden();
                }
            }

            &.image-gallery-controls-download {
                display: flex;
            }

            &.image-gallery-controls-share {
                display: flex;
            }

            &.image-gallery-controls-info {
                display: flex;
            }
        }
    }

    .icon-shrink {
        background-image: url('../icons/fwf/shrink_dark.svg');
    }

    .icon-fullscreen {
        background-image: url('../icons/fwf/fullscreen_dark.svg');
    }

    .icon-download {
        background-image: url('../icons/fwf/download_dark.svg');
    }

    .icon-close {
        background-image: url('../icons/fwf/close_dark.svg');
    }
}

.frame-type-image-gallery {
    figure {
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    figure figcaption {
        padding-left: 0;

        @include media-breakpoint-up(md) {
            margin: scale.px-to-rem(5) 0 scale.px-to-rem(32) 0;
        }

        &::before {
            content: none;
        }
    }

    .swiper.swiper-initialized figure figcaption {
        max-width: unset;
    }
}

.image-gallery-controls-download, .image-gallery-controls-info, .image-gallery-controls-share {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
    }
}

.swiper-fullscreen {
    .image-gallery-controls {
        top: auto;
        right: 29px;
        bottom: 52px;
        flex-direction: column;
        font-size: scale.px-to-rem(18);

        @include media-breakpoint-up(md) {
            top: 30px;
            bottom: auto;
            flex-direction: row-reverse;
        }

        > button, > a {
            width: scale.px-to-rem(70);
            height: scale.px-to-rem(40);
            margin-top: 10px;
            margin-left: 0;

            &.fullscreen-only {
                display: flex;
            }

            &.image-gallery-controls-download {
                display: flex;
            }

            &.image-gallery-controls-share {
                display: flex;
            }

            &.image-gallery-controls-info {
                display: flex;
            }

            &.image-gallery-controls-fullscreen {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            @include media-breakpoint-up(md) {
                margin-top: 0;
                margin-left: 10px;
            }
        }
    }
}

.dialog-open {
    .image-gallery-controls-download, .image-gallery-controls-info, .image-gallery-controls-share {
        display: block;
    }
}
