@use '../utilities/scale';
@use '../utilities/styles';
@use '../utilities/typography';

$menu-total-height: scale.px-to-rem(890);
$mega-menu-bottom-offset: scale.px-to-rem(140);
$mega-menu-bottom-padding: scale.px-to-rem(100);
$sub-menu-max-height: scale.px-to-rem(730);

@mixin menu-backdrop-filter($position: absolute, $height: 100%, $max-height: '') {
    position: $position;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: $height;

    @if $max-height != '' {
        max-height: $max-height;
    }

    backdrop-filter: blur(8px);
}

@mixin menu-stretched-layer($position: absolute, $height: 100%, $max-height: '') {
    &::after {
        position: $position;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: $height;

        @if $max-height != '' {
            max-height: $max-height;
        }

        background-color: var(--primary-color-translucent);
        opacity: 0.95;
        content: '';
    }
}

.close-button-line {
    width: scale.px-to-rem(16);
    min-height: scale.px-to-rem(1);
    background-color: var(--secondary-color); // Because of older iOS we don't use currentColor here
    transform-origin: center;
    transition: transform 0.5s;

    &:first-child {
        margin-bottom: scale.px-to-rem(8);
    }
}

// font-size must be smaller than icons, so they can be aligned vertically
.navigation-icon-item {
    font-size: 1px;
    line-height: 1;

    > div {
        font-size: var(--font-size);
        line-height: 1.4;
    }

    .button-icon {
        border-radius: 0;
        padding: scale.px-to-rem(2);

        &:focus {
            outline-width: scale.px-to-rem(1);
        }
    }
}

.navigation-icon-item-scilog {
    @include media-breakpoint-down(xxl) {
        display: none;
    }
}

.navigation-icon {
    display: inline-block;
    background-color: currentColor;
    mask-repeat: no-repeat;
    mask-size: contain;
}

.navigation-icon-newsletter {
    width: scale.px-to-rem(21);
    height: scale.px-to-rem(21);
    mask-image: url('../icons/newsletter.svg');

    @include media-breakpoint-up(md) {
        width: scale.px-to-rem(24);
        height: scale.px-to-rem(24);
        margin-top: scale.px-to-rem(5);
    }
}

.navigation-icon-login {
    width: scale.px-to-rem(21);
    height: scale.px-to-rem(22);
    mask-image: url('../icons/login.svg');

    @include media-breakpoint-up(md) {
        width: scale.px-to-rem(23);
        height: scale.px-to-rem(26);
    }
}

.navigation-icon-scilog {
    width: scale.px-to-rem(37);
    height: scale.px-to-rem(38);
    mask-image: url('../icons/scilog.svg');

    @include media-breakpoint-down(xxl) {
        display: none;
    }
}

.header-navigation-wrapper {
    border-bottom: styles.border-thin();
    color: var(--primary-color-text);

    ul {
        width: 100%;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }
}

.header-navigation-underlay {
    @include menu-stretched-layer();
    @include menu-backdrop-filter();
}

.header-menu {
    display: inline-block;
}

.header-navigation-container {
    display: flex;
    justify-content: flex-end;
    padding: 17px 29px;

    @include media-breakpoint-up(md) {
        padding: $header-bar-padding-vertical 29px;
    }

    .logo {
        z-index: 1; // raise above menu flyout
        display: inline-block;
        width: scale.px-to-rem(190);
        height: $header-bar-content-height-xs;
        margin-right: auto;
        background-color: currentColor;
        mask-repeat: no-repeat;
        mask-size: contain;

        @include media-breakpoint-up(md) {
            width: 250px;
            height: $header-bar-content-height-md;
        }
    }

    svg.icon-search {
        width: scale.px-to-rem(21);

        @include media-breakpoint-up(md) {
            width: scale.px-to-rem(23);
        }
    }
}

.main-menu-primary {
    @include typography.hyphenate();

    font-size: scale.px-to-rem(18);
    letter-spacing: 0.03em;

    a {
        display: block;
    }

    button {
        padding-right: 0;
        padding-left: 0;
        text-align: left;

        > .icon {
            flex-shrink: 0;
            width: scale.px-to-rem(20);
            height: scale.px-to-rem(20);
        }
    }

    li > button {
        border: none;
        background: transparent;
    }

    > li {
        > a, > button {
            @include typography.uppercase();
            border-bottom: none;
            padding-right: 0;
            padding-left: 0;
        }
    }

    button:focus {
        animation: none;
    }

    button:focus-visible {
        span {
            @include styles.focus-keyboard();
        }
    }

    .mega-menu {
        display: none;
    }

    .mega-menu-header-wrapper {
        display: flex;
        align-items: center;

        h2 {
            width: 100%;
            margin: 0;
            font-size: scale.px-to-rem(18);
            font-weight: 400;
        }

        h2 a {
            display: inline;
        }
    }

    .combo-menu-level-1 {
        li {
            > :is(button) {
                justify-content: flex-end;
            }
        }
    }
}

.main-menu {
    a { // stylelint-disable-line no-descending-specificity
        color: inherit;
        text-decoration: none;
    }

    :is(a,button):hover {
        text-decoration-line: underline;
    }

    .mega-menu-toggle {
        &:hover {
            text-decoration: none;
        }

        &:focus,
        &:active {
            outline: none;
        }
    }

    .newsletter-form-wrapper {
        button:hover {
            text-decoration: none;
        }
    }
}

.header-search-button {
    display: inline-flex;
    align-items: center;
    border: none;
    padding: 0;
    background: transparent;
    vertical-align: text-bottom;
}

.main-menu-language {
    z-index: 1;
}

.main-menu-language-tag {
    @include typography.uppercase();

    &.main-menu-language-inactive {
        opacity: 0.5;
    }
}

.header-mobile-menu-toggle {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: scale.px-to-rem(16);
    margin-left: scale.px-to-rem(12);
    border: none;
    padding: 0;
    background: transparent;
    vertical-align: text-bottom;

    &[aria-expanded='true'] {
        .close-button-line:first-child {
            margin-bottom: 0;
            transform: rotate(45deg) translateY(1px);
        }

        .close-button-line:last-child {
            transform: rotate(-45deg) translateY(-1px);
        }
    }
}

body.main-menu-open {
    overflow-y: hidden;

    .flyout-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        height: 100dvh; // full screen taking into account browser UI overlapping document area
        padding-top: $header-bar-height-xs;
        background: linear-gradient(to bottom, transparent $header-bar-height-xs, var(--primary-color-translucent) $header-bar-height-xs);

        @include media-breakpoint-up(md) {
            padding-top: $header-bar-height-md;
            background: linear-gradient(to bottom, transparent $header-bar-height-md, var(--primary-color-translucent) $header-bar-height-md);
        }
    }

    .main-menu {
        z-index: -1; // so it does not cover the menu bar
        display: flex;
        flex-direction: column;

        li > button[aria-expanded='true'] ~ .mega-menu {
            display: flex;
        }
    }
}

.header-search-overlay {
    .search-field {
        width: 100%;
    }

    input[type='search'] {
        width: 100%;
    }

    &.header-mobile .search-field {
        margin-top: 2em;
    }
}

body.search-open {
    overflow-y: hidden;

    .header-search-overlay {
        position: absolute;
        top: 0;
        left: 0;

        &.header-mobile {
            z-index: -1; // so it does not cover the menu bar
            display: flex;
            flex-direction: column;
            width: 100vw;
            height: 100vh;
            height: 100dvh; // full screen taking into account browser UI overlapping document area
            padding-top: $header-bar-height-xs;
            background: linear-gradient(to bottom, transparent $header-bar-height-xs, var(--primary-color-translucent) $header-bar-height-xs);

            @include media-breakpoint-up(md) {
                padding-top: $header-bar-height-md;
                background: linear-gradient(to bottom, transparent $header-bar-height-md, var(--primary-color-translucent) $header-bar-height-md);
            }
        }
    }
}

body.newsletter-open {
    .flyout-main-menu {
        display: none; // hide only primary menu, secondary menu still shown below
    }

    .flyout-newsletter.flyout-mobile {
        display: block;
        background: none; // the newsletter layer is placed on top of the translucent main menu layer
    }
}

// disables body scroll while the menu is open
body.no-scroll {
    overflow: hidden !important;
}

@include media-breakpoint-down(md) {
    .header-type-small {
        .header-navigation-underlay {
            opacity: 1;
        }
    }
}

// open mobile overlay (burger menu or search)
@include media-breakpoint-down(xxl) {
    .main-menu-primary {
        max-height: calc(100vh - #{scale.px-to-rem(145)});
        max-height: calc(100dvh - #{scale.px-to-rem(145)}); // height of the menu bar and secondary menu taking into account browser UI
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-color: currentColor transparent;

        &::-webkit-scrollbar {
            width: $scrollbar-size;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: $scrollbar-border-radius;
            background-color: currentColor;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #fff;
        }

        .main-menu-primary-button {
            @include styles.icon-text-color('plus', 0deg);

            transition: transform 0.5s ease-out 0.5s; // hacky: add a delay because of large max-height
        }

        :is(a) {
            margin-left: 29px;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &[aria-expanded='true'] {
                .main-menu-primary-button {
                    @include styles.icon-text-color('minus', 0deg);

                    transition: transform 0.5s ease-out;
                }
            }
        }

        > li {
            border-bottom: styles.border-thin();
            padding-top: scale.px-to-rem(18);

            > button {
                display: block;
                width: 100%;
                padding: 0 scale.px-to-rem(32) scale.px-to-rem(19);
            }
        }

        a {
            flex: 1 1 auto;
        }

        li {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        li > button {
            ~ ul {
                max-height: 0;
                overflow: hidden;
                transform: translateX(100%);
                visibility: hidden;
                transition: max-height 1s ease-out, transform 0s 1s, visibility 0s 1s;
            }

            &[aria-expanded='true'] ~ ul {
                display: block; // show UL of next level
                max-height: 9999px; // ideally as large as the content can be: height of LI * no. of LIs - for now is set just as an 'unlimited' height
                transform: translateX(0);
                visibility: visible;
                transition: max-height 2s linear, transform 1s ease-out;
            }
        }

        .mega-menu {
            flex-direction: column;
            width: 100%;
        }

        .mega-menu-toggle[aria-expanded='true'] {
            border-bottom: styles.border-thin();
        }

        .mega-menu-header-wrapper {
            margin: scale.px-to-rem(19) scale.px-to-rem(32);

            a {
                margin-left: 0;
            }
        }

        .combo-menu-level-1 {
            @include styles.color-scheme(secondary);

            > li {
                > :is(button) {
                    padding: 0 scale.px-to-rem(32);
                }
            }

            li {
                border-bottom: styles.border-thin();

                &.menu-list-with-submenu {
                    a {
                        margin-left: 29px;
                        padding-right: 29px;
                    }

                    button {
                        padding: 0 scale.px-to-rem(32);
                    }
                }

                &:not(.menu-list-with-submenu) a {
                    margin-right: 29px;
                    margin-left: 29px;
                    padding-right: 29px;
                }

                > :is(a) {
                    padding: scale.px-to-rem(14) 0;
                }
            }
        }

        .combo-menu-level-2 {
            background-color: #dfeff9;
            font-size: scale.px-to-rem(16);

            li:last-child {
                border-bottom: none;
            }
        }

        .combo-menu-level-3 {
            background-color: #fff;

            li:last-of-type {
                border-bottom: none;
            }
        }
    }

    .main-menu-secondary {
        margin-top: auto;
        border-top: styles.border-thin();
        padding: scale.px-to-rem(20) scale.px-to-rem(29) scale.px-to-rem(30);

        ul { // stylelint-disable-line no-descending-specificity
            display: flex;
            justify-content: space-between;
        }

        .main-menu-search {
            display: none;
        }
    }

    .flyout-newsletter {
        max-height: calc(100vh - #{scale.px-to-rem(145)});
        max-height: calc(100dvh - #{scale.px-to-rem(145)}); // height of the menu bar and secondary menu taking into account browser UI
    }

    .flyout-col-newsletter-desktop {
        margin: 2em 0;
        border-bottom: styles.border-thin();
        padding-bottom: 2em;
    }

    .menu-list-with-submenu.active {
        > :is(a,button) {
            border-bottom: none;
        }
    }
}

.flyout-col-newsletter-desktop, .flyout-col-social-media-desktop {
    h2 {
        @include typography.uppercase();

        margin-bottom: scale.px-to-rem(26);
        font-size: scale.px-to-rem(14);
        font-weight: 500;

        @include media-breakpoint-up(md) {
            font-size: scale.px-to-rem(18);
        }
    }
}

// desktop menu (mega menu)
@include media-breakpoint-up(xxl) {
    // *-desktop DIVs are only used for desktop layout, they have no meaning for mobile (but are shown)

    .header-navigation-wrapper {
        position: relative;
    }

    .header-navigation-container {
        align-items: center;
        max-width: $max-page-width;
        height: $header-bar-height-md;
        padding-top: 0;
        padding-bottom: 0;
    }

    .header-navigation-underlay {
        border-bottom: styles.border-thin();
    }

    .header-menu {
        flex: 1;
        height: 100%;
    }

    .header-mobile-menu-toggle {
        display: none;
    }

    .main-menu {
        display: flex;
        align-items: center;
        height: inherit;
    }

    .flyout-main-menu.flyout-desktop {
        flex: 1;
        height: inherit;
    }

    .main-menu-flyout-container-desktop {
        height: 100%;
    }

    .flyout-row-desktop {
        @include make-row();
        height: 100%;

        margin-right: 0;
        margin-left: 0;

        > * {
            @include make-col-ready();
        }
    }

    // .main-menu-flyout-claim-desktop, .flyout-col-claim-desktop {
    .flyout-col-claim-desktop {
        // we want the invisible claim to take part in the row
        display: block;
        height: 1px; // set a value so the claim does not affects the position of the main menu
        visibility: hidden;
    }

    .main-menu-flyout-claim-desktop {
        @include make-col(3);
    }

    .newsletter-flyout-claim-desktop {
        @include make-col(6);
    }

    // boost selector specificity (beat .header-navigation-wrapper ul)
    ul.main-menu-primary {
        @include make-col(8);

        // remove all Boostrap grid margins and paddings, apply equivalents in inner grid like structure
        flex-grow: 1;
        align-items: center;
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;

        > li {
            display: flex;
            height: 100%;
        }

        > li > button {
            text-align: center;
        }
    }

    .main-menu-primary {
        // minimalistic "row"
        display: flex;

        // minimalistic "col"
        > * {
            width: 25%;
        }

        > li > button:hover {
            opacity: 1 !important;
        }

        > li:has(> button:hover) ~ li > button,
        > li:has(~ li > button:hover) > button,
        &:has(> li > button[aria-expanded='true']) > li > button[aria-expanded='false'] {
            opacity: 0.5;
        }

        > li > :is(a,button) {
            display: block;
            width: 100%;
            padding-right: 0;

            @media screen and (prefers-reduced-motion: no-preference) {
                transition: opacity ease-out 0.2s;
            }
        }

        a {
            width: calc(100% - var(--bs-gutter-x));
        }

        ul {
            display: none;
        }

        .mega-menu-toggle {
            padding: 0 scale.fluid-size(8, 45, 1200, 1642);
        }

        .mega-menu {
            @include make-row();

            --mega-menu-height: calc(100vh - #{$header-bar-height-md});
            --mega-menu-max-height: calc(#{$menu-total-height} - #{$header-bar-height-md} - #{$mega-menu-bottom-offset});

            position: absolute;
            top: $header-bar-height-md;
            right: 0;
            left: 0;
            display: none;
            align-content: flex-start;
            height: var(--mega-menu-height);
            max-height: var(--mega-menu-max-height);
            margin-right: 0;
            margin-left: 0;
            padding-bottom: scale.px-to-rem(50);
            overflow-y: auto;

            &::before {
                @include menu-backdrop-filter(
                    $position: fixed,
                    $height: var(--mega-menu-height),
                    $max-height: var(--mega-menu-max-height)
                );

                content: '';
            }

            @include menu-stretched-layer(
                $position: fixed,
                $height: var(--mega-menu-height),
                $max-height: var(--mega-menu-max-height)
            );

            > * {
                @include make-col-ready();
            }
        }

        .mega-menu-header-wrapper {
            @include make-col(11);

            height: scale.px-to-rem(80);
        }

        .menu-column {
            flex-direction: column;
        }

        .menu-column li {
            position: relative;
            border-bottom: styles.border-thin();

            &:has(button[aria-expanded='true']) {
                border-bottom: none;
            }
        }

        .mega-menu-header-wrapper, .menu-column {
            margin-left: scale.fluid-size(20, 145, 1200, 1920);
        }

        .mega-menu > ul {
            @include make-col(3);

            display: flex;
        }

        > li > a {
            font-weight: 500;
            line-height: 1;

            > .icon {
                display: none;
            }
        }

        .mega-menu a {
            padding-top: scale.px-to-rem(13);
            padding-bottom: scale.px-to-rem(13);
        }

        .combo-menu-nav-icon-wrapper {
            .main-menu-primary-button {
                @include styles.icon-text-color('plus', 0deg);
                width: scale.px-to-rem(16);
                height: scale.px-to-rem(16);
            }

            &[aria-expanded='true'] {
                .main-menu-primary-button {
                    @include styles.icon-text-color('minus', 0deg);
                }
            }
        }
    }

    .main-menu-secondary {
        z-index: 1; // raise above wrapper for main menu
        font-size: var(--font-size-small);

        ul {
            display: flex;
            align-items: center;
        }

        li { // stylelint-disable-line no-descending-specificity
            margin-left: scale.px-to-rem(20);
        }
    }

    .header-search-button {
        &.header-mobile {
            display: none;
        }

        &.header-desktop {
            position: relative;
            z-index: 1; // raise above open search field
            display: inline-flex;
        }
    }

    .header-search-overlay {
        form {
            padding-right: 10rem; // we need a big gap for enlarged font sizes (WCAG)
        }
    }

    .flyout-col-newsletter-desktop {
        @include make-col(4);

        border-right: styles.border-thin();
        border-left: styles.border-thin();
    }

    .flyout-col-social-media-desktop {
        @include make-col(2);

        border-right: styles.border-thin();

        &:last-child {
            border-right: 0;
        }
    }

    // the mega-menu is fully visible
    body.main-menu-open {
        // leave a bit of the document visible (never fill the viewport) - 100px is arbitrary, 600px is per design
        // --mega-menu-height: min(100lvh - 100px, 600px);

        overflow-y: unset;

        .main-menu {
            position: static;
            flex-direction: row;
            width: auto;
            height: 100%;
            padding-top: 0;
            background: none;
        }

        // boost selector specificity (beat .header-navigation-wrapper ul)
        ul.main-menu-primary {
            margin-top: 0;
            margin-bottom: 0;
        }

        .main-menu-primary {
            height: 100%;
            font-size: var(--font-size-small);
            line-height: 1.3;

            .mega-menu-toggle[aria-expanded='true'] {
                position: relative;
                border-right: styles.border-thin();
                border-left: styles.border-thin();
            }

            // show UL of next level
            li > button[aria-expanded='true'] ~ ul {
                display: block;
            }

            > li {
                display: flex;
                scrollbar-color: currentColor transparent;

                > a {
                    margin-right: calc(var(--bs-gutter-x) * 0.5);
                    margin-left: calc(var(--bs-gutter-x) * 0.5);
                }
            }
        }

        .combo-menu-level-1 {
            height: calc(100vh - $mega-menu-bottom-offset - $mega-menu-bottom-padding);
            max-height: calc($sub-menu-max-height - $mega-menu-bottom-offset - $mega-menu-bottom-padding);
            overflow-y: auto;
            font-size: var(--font-size);

            &::-webkit-scrollbar {
                width: $scrollbar-size;
            }

            &::-webkit-scrollbar-button {
                display: none;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: $scrollbar-border-radius;
                background-color: currentColor;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #fff;
            }

            button { // stylelint-disable-line no-descending-specificity
                display: flex;
                align-items: center;
                align-self: stretch;
                justify-content: center;
                padding: 0 scale.px-to-rem(8);

                &[aria-expanded='true'] {
                    .main-menu-primary-button {
                        @include styles.icon-text-color('minus', 0deg);
                    }

                    + ul {
                        border-top: styles.border-thin();
                    }
                }
            }

            li { // stylelint-disable-line no-descending-specificity
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: space-between;

                > :is(a, button) {
                    flex: 1 1 auto;
                }

                &.menu-list-with-submenu button:hover {
                    @include styles.color-scheme(secondary);
                }

                a:hover {
                    @include styles.color-scheme(secondary);
                    text-decoration: none;
                }
            }

            > li { // stylelint-disable-line no-descending-specificity
                margin-top: scale.px-to-rem(20);

                > a {
                    @include typography.uppercase();

                    padding-left: scale.px-to-rem(6);
                }

                &:not(.menu-list-with-submenu) > a {
                    width: 100%;
                }

                > :is(a,button) {
                    border-bottom: none;
                }
            }

            .main-menu-primary-button { // stylelint-disable-line no-descending-specificity
                @include styles.icon-text-color('plus', 0deg);
            }
        }

        .combo-menu-level-2 {
            font-size: scale.px-to-rem(18);

            li { // stylelint-disable-line no-descending-specificity
                &:last-child :is(a,button) {
                    border-bottom: none;
                }
            }

            > li > a {
                padding-left: scale.px-to-rem(13);
            }

            .main-menu-primary-button { // stylelint-disable-line no-descending-specificity
                @include styles.icon-text-color('plus', 0deg);
            }

            button[aria-expanded='true'] {
                .main-menu-primary-button {
                    @include styles.icon-text-color('minus', 0deg);
                }
            }
        }

        .combo-menu-level-3 {
            li a {
                padding-left: scale.px-to-rem(26);
                opacity: 0.8;

                &:hover {
                    color: inherit;
                    background-color: transparent;
                    text-decoration: underline;
                }
            }
        }

        .menu-list-with-submenu.active {
            > :is(a,button) {
                border-bottom: none;
            }
        }
    }

    body.search-open {
        overflow-y: auto;

        .header-search-overlay {
            &.header-mobile {
                display: none;
            }

            &.header-desktop {
                top: 14px;
                display: block;
                width: 100%;
                padding: 0 139px;
            }
        }

        .main-menu-primary {
            display: none;
        }
    }

    body.newsletter-open {
        .flyout-newsletter.flyout-desktop {
            position: absolute;
            top: $header-bar-height-md;
            right: 0;
            left: 0;
            display: block;
            padding-top: scale.px-to-rem(40);
            padding-bottom: scale.px-to-rem(40);

            &::before {
                @include menu-backdrop-filter();
            }

            @include menu-stretched-layer();

            > .container-content {
                height: 100%;
            }
        }

        .main-menu-secondary {
            margin-left: auto;
        }

        .newsletter-flyout-claim-desktop {
            visibility: visible;
        }
    }
}

.flyout-col-social-media-wrapper {
    margin-top: scale.px-to-rem(50);

    ul {
        justify-content: flex-start;
    }

    li {
        margin-left: 0;
    }
}

.newsletter-form-inner-wrapper {
    margin-top: scale.px-to-rem(50);
}
