@use '../utilities/scale';
@use '../utilities/typography';

.claim {
    @include typography.hyphenate();

    line-height: 1;

    span {
        display: inline-block;
        margin-left: 1.5em;
    }
}
